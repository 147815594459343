import * as angular from "angular";
import FeatureToggleRouter from "Components/samFeatureToggle/FeatureToggleRouter";

/**
 * Component definition-object for the samFeatureToggleComponent.
 * samFeatureToggle is for convenience when we need to feature-flag/feature-toggle
 * some content in the markup. It depends on FeatureToggleRouter to provide
 * it with the information about the given feature-flag and whether or not
 * it should be displayed.
 *
 * Args/Attributes:
 *      featureName : string (Required)
 *          The name of a feature that we want to check if is enabled or disabled.
 *      whenEnabled : boolean
 *          When this attribute is truethe contents are disabled if
 *          FeatureToggleRouter.isDisabled returns true for the given featureName.
 *
 * Example usage:
 *     <sam-feature-toggle feature-name="myAwesomeNewFeature" when-enabled="true">
 *         <h1>This will only be visible if myAwesomeNewFeature is enabled</h1>
 *     </sam-feature-toggle>
 *     <sam-feature-toggle feature-name="myAwesomeNewFeature" when-enabled="false">
 *         <h1>This will only be visible if myAwesomeNewFeature is disabled</h1>
 *     </sam-feature-toggle>
 */
const featureToggleComponent: ng.IComponentOptions = {
  bindings: {
    featureName: "<",
    whenEnabled: "<"
  },
  template: '<div ng-if="::$ctrl.visible" ng-transclude></div>',
  transclude: true,
  controller: class SamFeatureToggleController {
    static $inject: string[] = ["$element"];

    // Bindings
    private featureName: string;
    private whenEnabled: boolean;

    private currentDOMElement: ng.IRootElementService;
    private visible: boolean = true;

    constructor($element: ng.IRootElementService) {
      this.currentDOMElement = $element;
    }

    /**
     * Initialization of the component. Checks with FeatureToggleRouter and asks
     * whether or not the contents between <sam-feature-toggle> should be displayed.
     */
    $onInit = (): void => {
      this.whenEnabled = Boolean(this.whenEnabled);
      // Return from onInit if the feature-name is missing
      if (typeof this.featureName !== "string" || this.featureName.length < 1) {
        console.warn("sam-feature-toggle: Missing feature name!");
        return;
      }

      if (this.whenEnabled === true) {
        this.visible = FeatureToggleRouter.isEnabled(this.featureName);
      } else if (this.whenEnabled === false) {
        this.visible = FeatureToggleRouter.isDisabled(this.featureName);
      } else {
        this.visible = false;
        console.warn('sam-feature-toggle: "when-enabled" must be set');
      }

      // The ng-if and ng-transclude take care of cleaning up scopes that
      // might be contained in the <sam-feature-toggle> tags, this is just for
      // a little peace-of-mind that the element will definitely be removed.
      if (!this.visible) {
        this.currentDOMElement.remove();
      }
    };
  }
};

export default featureToggleComponent;
