import * as angular from "angular";

import samFeatureToggle from "Components/samFeatureToggle";
import samAsyncDropdown from "Components/samAsyncDropdown";
import samAttributePopover from "Components/samAttributePopover";
import samDatePicker from "Components/samDatePicker";
import samDateTimePicker from "Components/samDateTimePicker";
import samDomesticStatus from "Components/samDomesticStatus";
import samDomesticStatusTile from "Components/samDomesticStatusTile";
import samDynamicContent from "Components/samDynamicContent";
import samEditableDate from "Components/samEditableDate";
import samDownloadFile from "Components/samDownloadFile";
import samFavorite from "Components/samFavorite";
import samInput from "Components/samInput";
import samInputNumber from "Components/samInputNumber";
import samIntro from "Components/samIntro";
import samItemsPerPage from "Components/samItemsPerPage";
import samMultiSelect from "Components/samMultiSelect";
import samMyFilter from "Components/samMyFilter";
import samSaving from "Components/samSaving";
import samShipmentStatus from "Components/samShipmentStatus";
import samTabbable from "Components/samTabbable";
import samVoyagePicker from "Components/samVoyagePicker";
import samPluralize from "Components/samPluralize";

angular
  .module("serviceWebApp")
  .component("samFeatureToggle", samFeatureToggle)
  .component("samAsyncDropdown", samAsyncDropdown)
  .component("samAttributePopover", samAttributePopover)
  .component("samDatepicker", samDatePicker)
  .component("samDateTime", samDateTimePicker)
  .component("samDomesticStatus", samDomesticStatus)
  .component("samDomesticStatusTile", samDomesticStatusTile)
  .component("samDynamicContent", samDynamicContent)
  .component("samEditableDate", samEditableDate)
  .component("samDownloadFile", samDownloadFile)
  .component("samFavorite", samFavorite)
  .component("samInput", samInput)
  .component("samInputNumber", samInputNumber)
  .component("samIntro", samIntro)
  .component("samItemsPerPage", samItemsPerPage)
  .component("samMultiselect", samMultiSelect)
  .component("samMyFilter", samMyFilter)
  .component("samSaving", samSaving)
  .component("samShipmentStatus", samShipmentStatus)
  .component("samTabbable", samTabbable)
  .component("samVoyagepicker", samVoyagePicker)
  .component("samPluralize", samPluralize);
