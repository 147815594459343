import { TransitionService, Transition } from "@uirouter/core";

function config(
  $compileProvider: ng.ICompileProvider,
  $translateProvider: ng.translate.ITranslateProvider,
  $locationProvider: ng.ILocationProvider,
  $httpProvider: ng.IHttpProvider,
  $qProvider: ng.IQProvider
) {
  const env = process.env.NODE_ENV;
  const debug = process.env.DEBUG;

  $httpProvider.interceptors.push("httpInterceptor");
  $httpProvider.defaults.withCredentials = true;
  $httpProvider.useApplyAsync(true);

  // $qProvider.errorOnUnhandledRejections(false);

  $locationProvider.html5Mode(true);

  $compileProvider.debugInfoEnabled(false);
  $compileProvider.preAssignBindingsEnabled(true);

  // Translations
  $translateProvider.useLoader("TranslationLoader");
  $translateProvider.useLocalStorage();
  $translateProvider.preferredLanguage("is");
  $translateProvider.useSanitizeValueStrategy("");
  $translateProvider.useInterpolation("CustomInterpolator");
}

config.$inject = [
  "$compileProvider",
  "$translateProvider",
  "$locationProvider",
  "$httpProvider",
  "$qProvider"
];
export default config;
