/**
 * sam-input component
 * @param  {String} name           Name of the attribute
 * @param  {String} title          Title of the attribute
 * @param  {String} onAttributeSet Function that is run when attribute value is set
 */
const samInputComponent: ng.IComponentOptions = {
  bindings: {
    name: "<",
    title: "<",
    onValueSet: "<"
  },
  template: `<div class="input-group" style="width:330px;">
                <span class="input-group-addon">{{$ctrl.title}}</span>
                <input type="text" class="form-control" ng-model="$ctrl.result"
                    ng-keyup="$event.keyCode == 13 && $ctrl.submit()">
                <span class="input-group-btn">
                    <button class="btn btn-default" type="submit" ng-click="$ctrl.submit()">submit</button>
                </span>
            </div>`,
  controller: class SamInputController {
    // Bindings
    private name: string;
    private title: string;
    private onValueSet: Function;

    private result: string;

    submit = (): void => {
      this.onValueSet(this.name, this.result);
    };
  }
};

export default samInputComponent;
