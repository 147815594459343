import { DomesticService } from "Services";
import * as tpl from "Components/samDomesticStatusTile/samDomesticStatusTile.html";

/**
 * samDomesticStatusTile component represents one piece in the domestic tile.
 * It displays correct status text for each booking and pass in correct status
 * information to samDomesticStatus component
 *
 * Arguments/Attributes
 *     trackTraceStatus : string
 *         Number value of current booking status.
 *
 */
const samDomesticStatusTileComponent: ng.IComponentOptions = {
  template: `${tpl}`,
  bindings: {
    trackTraceStatus: "<"
  },
  controller: class SamDomesticStatusTitleController {
    // Bindings
    private trackTraceStatus: number;

    private tileStatus: number;
    private tileStatusText: string;

    $onInit = (): void => {
      const tileStatus: number = DomesticService.trackTraceStatusToTileStatus(
        this.trackTraceStatus
      );
      const tileStatusText: string = DomesticService.getTileStatusText(
        tileStatus
      );

      this.tileStatus = tileStatus;
      this.tileStatusText = tileStatusText;
    };
  }
};

export default samDomesticStatusTileComponent;
