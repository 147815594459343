import * as angular from "angular";
import { UI_ROUTER_REACT_HYBRID } from "@uirouter/react-hybrid";

import { NavStore, NotificationStore, UserCacheStore, UserStore } from "Stores";

import {
  CompanyService,
  NotificationService,
  SamskipNotify,
  SentryService,
  TranslationService,
  TutorialService,
  UserService
} from "Services";
import { Object } from "es6-shim";
import "./polyfills/string";

declare var GlobalConfig: GlobalConfig;

// Dynamically load environment config based on NODE_ENV
// and attach it to the window GlobalConfig object
// so we don't have to inject it into all components
// and can access it outside modules (in a browser)
const env = process.env.NODE_ENV;

if (!env) {
  throw new Error("Environment is not specified");
}

const envConfig = require(`../../env/${env.trim()}.json`);
const features = require("./globals/features.json");
const page = require("./globals/page.json");
const globals = require("./globals/globals.json");
(<any>window).GlobalConfig = Object.assign(
  (<any>window).GlobalConfig || {},
  envConfig,
  globals,
  features,
  page
);

// Initialize the Angular module
const dependencies: string[] = [
  "ui.router",
  UI_ROUTER_REACT_HYBRID,
  "ui.select",
  "ngCookies",
  "pascalprecht.translate",
  "ngSanitize",
  "RecursionHelper",
  "ui.bootstrap",
  "ngMessages",
  "pusher-angular",
  "bcherny/ngimport"
];

angular.module("serviceWebApp", dependencies);
