import { UserManagerService } from "Services";

/**
 * samCheckLoginid directive checks if an entered username already exists by
 * communicating with the AuthAPI's UserManagerService.
 * When an input[type=text] element is decorated with the 'check-loginid'
 * attribute, the directive injects an asynchronous 'loginid' validator
 * into Angular's $validators pipeline which validates the input depending
 * on the response returned from the server.
 */
function samCheckLoginid() {
  return {
    require: "ngModel",
    restrict: "A",
    link: function link(
      scope: any,
      element: ng.IAugmentedJQuery,
      attrs: ng.IAttributes,
      ctrl: ng.IController
    ) {
      ctrl.$asyncValidators.loginid = function loginid(
        modelValue: string,
        viewValue: string
      ) {
        // Element is disabled and untouched
        if (attrs.disabled && ctrl.$pristine && ctrl.$untouched) {
          return Promise.resolve();
        }
        // Element's value (username/loginid) has changed
        return new Promise((resolve, reject) => {
          UserManagerService.loginIdIsTaken(viewValue).then(
            (data: boolean) => {
              if (data === false) {
                resolve();
              } else if (data === true) {
                reject();
              } else {
                resolve();
              }
            },
            () => {
              reject();
            }
          );
        });
      };
    }
  };
}

export default samCheckLoginid;
