import * as angular from "angular";
import { $timeout } from "ngimport";
import { Object } from "es6-shim";
import { UserService } from "Services";
import * as myFilterTemplate from "Components/samMyFilter/samMyFilter.html";

const samMyFilterComponent: ng.IComponentOptions = {
  bindings: {
    settingsName: "@",
    filterPrefix: "=",
    filter: "<",
    searchQuery: "<",
    dateFrom: "=",
    dateTo: "="
  },
  template: `<span class="my-filter-button"
                ng-class="{ disabled: $ctrl.usingMyFilter || $ctrl.initialLoad || $ctrl.searchQuery}"
                data-ng-click="$ctrl.saveFilter()">
            <i class="fa fa-bookmark"></i>
            <span style="margin-left:5px;"
                data-translate="{{$ctrl.initialFilterExists ? 'LABEL_UPDATE_FILTER' : 'LABEL_SAVE_FILTER'}}">
                Vista síu</span>
            </span>
            <span class="my-filter-button"
                ng-class="{ disabled: $ctrl.usingMyFilter || !$ctrl.initialFilterExists }"
                style="margin-left: 10px;"
                data-ng-click="$ctrl.loadFilter()">
            <i class="fa fa-filter"></i>
            <span style="margin-left:5px;" data-translate="LABEL_USE_FILTER">Nota síu</span>
            </span>`,
  controller: class SamMyFilterController {
    static $inject: string[] = ["$scope"];

    // Bindings
    private settingsName: string;
    private filterPrefix: string;
    private filter: any;
    private searchQuery: string;
    private dateFrom: Date;
    private dateTo: Date;

    private currentScope: any;
    private pageName: string;
    private settings: any = {};
    private loadingFilter: boolean = false;
    private usingMyFilter: boolean = false;
    private initialFilterExists: boolean = false;
    private initialLoad: boolean = true;

    constructor($scope: any) {
      this.currentScope = $scope;
    }

    /**
     * Initialize the controller with the 'pageName' and 'prefix'
     * which determines which filter to manipulate.
     */
    $onInit = (): void => {
      this.pageName = this.settingsName;

      // Get the filter saved for page 'pageName'
      const settingsValue = UserService.getLocalWebSettingValue(this.pageName);
      this.settings = settingsValue ? JSON.parse(settingsValue) : {};
      this.initialFilterExists = this.settings.filter != null && this.settings;

      // varriable controller load finished needs to be set in parent controller,
      // this is so the watch isnt fired preemtively.
      // TODO: Get rid of $watch
      this.currentScope.$watch(
        <any>angular.bind(this, () => {
          return this.filter;
        }),
        (newValue: any, oldValue: any) => {
          if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
            // this is so the using my filter isn't toggled when loading the filter.
            if (this.loadingFilter) {
              this.filterPrefix = "LABEL_MYFILTER";
              this.loadingFilter = false;
            } else {
              this.usingMyFilter = false;
              this.filterPrefix = "";
              this.initialLoad = false;
            }
          }
        },
        true
      );
    };

    // Save the filter object to websettings
    saveFilter = (): void => {
      if (!(this.usingMyFilter || this.initialLoad || this.searchQuery)) {
        this.settings.filter = Object.assign({}, this.filter);

        // If dates available save them aswell
        if (this.dateFrom && this.dateTo) {
          this.settings.filter.dateFrom = this.dateFrom;
          this.settings.filter.dateTo = this.dateTo;
        }
        UserService.setWebSetting(this.pageName, JSON.stringify(this.settings));

        // We need $timeout here because we are in the middle of a digest
        // This code gets run after the next digest
        // I'm not entirely sure why the digest is run before the click handler finishes
        $timeout(() => {
          this.usingMyFilter = true;
          this.initialFilterExists = true;
          this.filterPrefix = "LABEL_MYFILTER";
        });
      }
    };

    // Load the saved filter.
    loadFilter = (): void => {
      if (!(this.usingMyFilter || !this.initialFilterExists)) {
        // If dates in storage restore them
        if (this.settings.filter.dateFrom && this.settings.filter.dateTo) {
          this.dateFrom = this.settings.filter.dateFrom;
          this.dateTo = this.settings.filter.dateTo;
        }
        const myFilterObject = {
          filter: this.settings.filter,
          dateFrom: this.settings.filter.dateFrom,
          dateTo: this.settings.filter.dateTo
        };

        // Custom function to load controller specific values.
        this.currentScope.$emit("loadMyFilter", myFilterObject);

        this.usingMyFilter = true;
        this.loadingFilter = true;
        this.initialLoad = false;
        this.filterPrefix = "LABEL_MYFILTER";
      }
    };
  }
};

export default samMyFilterComponent;
