import { $document, $timeout } from "ngimport";
import { ArrayUtilities } from "Utilities";

const samTabbableComponent: ng.IComponentOptions = {
  bindings: {
    closeCallback: "&tabbableClose",
    executeCallback: "&tabbableExecuteCallback",
    focusOnCloseID: "@tabbableFocusOnCloseId",
    excludeFocusStringID: "@tabbableExcludeFocusId",
    firstFocusStringID: "@tabbableFirstFocusId",
    firstFocusStringElement: "@tabbableFirstFocusElement"
  },
  controller: class SamTabbableCtrl {
    static $inject: string[] = ["$element"];

    closeCallback: Function;
    executeCallback: Function;
    focusOnCloseID: string;
    excludeFocusStringID: string;
    firstFocusStringID: string;
    firstFocusStringElement: string;

    modal: any;
    allTabbableElements: Element[];
    firstTabbableElement: Element;
    lastTabbableElement: Element;
    keyCode: number;
    currTableId: string;
    currTableClass: string;
    currTableElementFocused: Element;
    tableMode = false;
    currTableTabbableElements: NodeListOf<Element>;

    CONST_ELEMENT: string = "input[type=text]";

    tabbableElements: string =
      "a[href], area[href], input:not([disabled])," +
      "select:not([disabled]), textarea:not([disabled])," +
      "button:not([disabled]), iframe, object, embed, *[tabindex]," +
      "*[contenteditable]";

    keyboardButtons = {
      ESC: { keyCode: 27 },
      TAB: { keyCode: 9 },
      UPARROW: { keyCode: 38 },
      DOWNARROW: { keyCode: 40 },
      ENTER: { keyCode: 13 }
    };

    constructor(private $element: ng.IRootElementService) {
      $element.on("$destroy", () => {
        $timeout(() => {
          const el = this.findFirstElementInStringByID(this.focusOnCloseID);
          this.setFocus(el);
        }, 0);
      });
    }

    $onInit = (): void => {
      $timeout(() => {
        this.modal = this.$element[0].querySelector("div");
        this.keepFocus(this.modal);
        this.initializeFirstFocus();
      }, 0);
    };

    /**
     * Initialize first focus
     * @return {[type]} [description]
     */
    private initializeFirstFocus = (): void => {
      const input = this.findFirstElement(this.modal);
      // Focus first element if it's found
      if (input instanceof HTMLElement) {
        this.setFocus(input);
        this.activateTableModeIfNeeded(input);
      }
    };

    /**
     * Find element from vm.firstFocusStringID if it's defined.
     * Else find query from vm.firstFocusStringElement or CONST_ELEMENT
     * @return {element} [Element to focus]
     */
    private findFirstElement = (currentModal: any): Element => {
      let returnElement = null;

      if (!this.firstFocusStringID) {
        if (this.firstFocusStringElement) {
          returnElement = this.findFirstElementInStringByQuery(
            this.firstFocusStringElement
          );
        }
      } else {
        returnElement = this.findFirstElementInStringByID(
          this.firstFocusStringID
        );
      }

      // This is used just in case when no element from above is found
      if (!returnElement) {
        returnElement = this.findFirstElementAutomatically(currentModal);
      }
      return returnElement;
    };

    /**
     * Find first element to focus on. If no 'CONST_ELEMENT' is found,
     * then get first element in inside 'div'.
     * @return {object|element} [Element to focus]
     */
    private findFirstElementAutomatically = (currentModal: any): Element => {
      let returnElement = currentModal.querySelector(this.CONST_ELEMENT);
      if (!returnElement) {
        returnElement = currentModal.querySelectorAll(this.tabbableElements);
        returnElement = this.filterTabbableElements(returnElement)[0];
      }
      return returnElement;
    };

    // When key is pushed
    private keyListenerDown = (event: KeyboardEvent): void => {
      this.setTabbableElements(this.modal);
      // Polyfill to prevent the default behavior of events
      event.preventDefault =
        event.preventDefault ||
        function prevent() {
          event.returnValue = false;
        };
      this.detectKeyPressed(event);
    };

    // When key is released
    private keyListenerUp = (event: KeyboardEvent): void => {
      const document = $document[0] as any;
      this.activateTableModeIfNeeded(document.activeElement);
    };

    private keepFocus = (context: any): void => {
      this.setTabbableElements(context);

      // Event listerners
      this.$element.on("keydown", (e: any) => {
        this.keyListenerDown(e);
      });

      this.$element.on("keyup", (e: any) => {
        this.keyListenerUp(e);
      });
    };

    private setTabbableElements = (c: any): void => {
      this.allTabbableElements = this.filterTabbableElements(
        c.querySelectorAll(this.tabbableElements)
      );
      this.firstTabbableElement = this.allTabbableElements[0];
      this.lastTabbableElement = this.allTabbableElements[
        this.allTabbableElements.length - 1
      ];
    };

    /**
     * Filter elements from tabbable elements that have been either been
     * marked as excluded ids or don't fullfill the requirement that it
     * has to have to be focusable.
     * @param  {array|elements} elements [Array of elements]
     * @return {array|elements}          [Array of filtered elements]
     */
    private filterTabbableElements = (elements: any[]): Element[] => {
      let returnElements = this.excludeUnfocusableElements(elements);
      if (this.excludeFocusStringID) {
        const excludeFocusArray = this.splitElements(this.excludeFocusStringID);
        returnElements = this.excludeFromElements(
          returnElements,
          excludeFocusArray
        );
      }
      if (!returnElements) {
        returnElements = elements;
      }
      return returnElements;
    };

    /**
     * 'excludeArray' will be pulled from 'elements' array.
     * @param  {array|elements} elements        [Array of elements to be filtered]
     * @param  {array|elements} excludeArray    [Array of ids that will be excluded]
     * @return {[type]}                         [Array of filtered elements]
     */
    private excludeFromElements = (
      elements: Element[],
      excludeArray: string[]
    ): Element[] => {
      const newTabbableElements: any[] = [];
      elements.forEach((value: any) => {
        if (excludeArray.indexOf(value.id) === -1) {
          newTabbableElements.push(value);
        }
      });
      return newTabbableElements;
    };

    /**
     * If elements in 'elements' array are disabled,  then exclude it
     * from the array.
     * @param  {array|elements} elements [Array of elements to be filtered]
     * @return {array|elements}          [Array of filtered elements]
     */
    private excludeUnfocusableElements = (elements: Element[]): Element[] => {
      const returnElements: any[] = [];
      Array.prototype.slice.call(elements).forEach((value: any) => {
        if (!value.disabled) {
          returnElements.push(value);
        }
      });
      return returnElements;
    };

    /**
     * Get keycode and check if particular
     * keys are pressed.
     * @param  {[type]} event [description]
     * @return {[type]}       [description]
     */
    private detectKeyPressed = (event: KeyboardEvent): void => {
      if (!this.tableMode) {
        this.detectKeyPressedOriginalMode(event);
      } else {
        this.detectKeyPressedTableMode(event);
      }
    };

    /*
     ************************************************************
     *  Normal mode start
     *  - Normal mode is when you tab through elements in browser
     *  like usually.
     ************************************************************
     */
    private detectKeyPressedOriginalMode = (event: KeyboardEvent): void => {
      this.keyCode = event.which || event.keyCode;
      if (this.getKeyCode("ESC")) {
        this.escKeyPressed();
      } else if (this.getKeyCode("TAB")) {
        this.tabKeyPressed(event);
      }
    };

    /**
     * Reactions that happens when esc button is pressed.
     * - closeCallback() callback private is called
     * - focus set on that element that had its id passed
     *     in this component with binding.
     */
    private escKeyPressed = () => {
      this.closeCallback();
    };

    /**
     * Reactions that happens when tab button is pressed and when
     * shift button and tab button are both pressed at the same time.
     */
    private tabKeyPressed = (event: KeyboardEvent): void => {
      // Move focus to first element that can be tabbed if Shift isn't used
      if (event.target === this.lastTabbableElement && !event.shiftKey) {
        event.preventDefault();
        this.setFocus(this.firstTabbableElement);
        // Move focus to last element that can be tabbed if Shift is used
      } else if (event.target === this.firstTabbableElement && event.shiftKey) {
        event.preventDefault();
        this.setFocus(this.lastTabbableElement);
      }
    };

    /*
     ************************************************************
     * Normal mode finished
     ************************************************************
     */

    /*
     ************************************************************
     *  Table mode start
     *  - Table mode is when any table in viewport is focused
     *  and <tr> inside table is focused by using up and down
     *  arrow buttons.
     ************************************************************
     */
    private detectKeyPressedTableMode = (event: KeyboardEvent): void => {
      this.keyCode = event.which || event.keyCode;

      if (this.getKeyCode("ESC")) {
        this.escKeyPressed();
      } else if (this.getKeyCode("UPARROW")) {
        this.upArrowKeyPressed(event);
      } else if (this.getKeyCode("DOWNARROW")) {
        this.downArrowKeyPressed(event);
      } else if (this.getKeyCode("TAB")) {
        this.deactivateTableMode(event);
      } else if (this.getKeyCode("ENTER")) {
        this.executeCallback({
          index: Array.prototype.slice
            .call(this.currTableTabbableElements)
            .indexOf(event.target)
        });
      }
    };

    /**
     * When focusing table and pressing down arrow key, place focus
     * on next <tr> element in the table.
     * @param  {event} event    [Current event]
     */
    private downArrowKeyPressed = (event: KeyboardEvent): void => {
      event.preventDefault();
      const tableSize = this.getSizeOfCurrentTable();
      let index = Array.prototype.slice
        .call(this.currTableTabbableElements)
        .indexOf(event.target);
      if (tableSize && tableSize - 1 <= index) {
        this.currTableElementFocused = this.currTableTabbableElements[0];
      } else {
        index += 1;
        this.currTableElementFocused = this.currTableTabbableElements[index];
      }
      this.setFocus(this.currTableElementFocused);
    };

    /**
     * When focusing table and pressing up arrow key, place focus
     * on previous <tr> element in the table.
     * @param  {event} event    [Current event]
     */
    private upArrowKeyPressed = (event: KeyboardEvent): void => {
      event.preventDefault();
      const tableSize = this.getSizeOfCurrentTable();
      if (!tableSize) return;
      let index = Array.prototype.slice
        .call(this.currTableTabbableElements)
        .indexOf(event.target);
      if (index <= 0) {
        this.currTableElementFocused = this.currTableTabbableElements[
          tableSize - 1
        ];
      } else {
        index -= 1;
        this.currTableElementFocused = this.currTableTabbableElements[index];
      }
      this.setFocus(this.currTableElementFocused);
    };

    /**
     * Returns numbero of <tr> elements of current table that is focused.
     * @return {number} [Number of <tr> in table]
     */
    private getSizeOfCurrentTable = (): number | undefined => {
      const tableElement = this.$element[0].querySelector(
        `#${this.currTableId}`
      );
      if (!tableElement) return;

      const trElement = tableElement.querySelectorAll("tbody tr");
      return trElement.length;
    };

    private deactivateTableMode(event: KeyboardEvent) {
      this.tableMode = false;
      if (!event.shiftKey) {
        event.preventDefault();
        this.setFocus(this.getNearestElementAboveCurrentTable());
        // Move focus to last element that can be tabbed if Shift is used
      } else if (event.shiftKey) {
        event.preventDefault();
        this.setFocus(this.getNearestElementBelowCurrentTable());
      }
    }

    /**
     * Split elements in 'inStr' into array
     * @param  {string} inStr   [string to split]
     * @return {array|string}   [array of split string]
     */
    private splitElements(inStr: string): string[] {
      const re = /[.,;:]/;
      return ArrayUtilities.without(inStr.split(re), [""]);
    }

    /**
     * Only if tableMode isn't active, then check if element
     * is <tr> to check if activation of table mode is
     * needed.
     * @param  {object|element} element [HTML Element]
     * @param  {event} event            [Event]
     */
    private activateTableModeIfNeeded = (
      element: Element | undefined | null
    ): void => {
      if (!this.tableMode && this.isElementTableRow(element)) {
        this.activateTableMode();
      }
    };

    private activateTableMode = (): void => {
      this.tableMode = true;
      // We assume that tbody tr is focused. Grand parent (lol...) of that
      // element is table itself.
      const document = $document[0] as any;
      this.currTableId = (<any>(
        document.activeElement!.parentElement
      )).parentElement.id;
      const tableElement = this.$element[0].querySelector(
        `#${this.currTableId}`
      );

      if (!tableElement) return;

      // Select all table elements from current table
      this.currTableTabbableElements = tableElement.querySelectorAll(
        "tbody tr"
      );
      this.currTableClass = this.currTableTabbableElements[0].className;

      this.setFirstFocusInsideTable(this.currTableTabbableElements);
    };

    private setFirstFocusInsideTable(
      currTableElements: NodeListOf<Element>
    ): void {
      this.currTableElementFocused = currTableElements[0];
      (<HTMLElement>this.currTableElementFocused).focus();
    }

    /*
     ***************************************************************************
     *  It's enough to call one of these two privates to use the logic below:
     *     - getNearestElementAboveCurrentTable()
     *     - getNearestElementBelowCurrentTable()
     *  Definitions:
     *  - CurrentTable: When table is focused, current table is used to
     *      describe it.
     ***************************************************************************
     */

    /**
     * Get nearest element above current table.
     * In other words, search for the element that has focus right before
     * focusing the table.
     * @return {object|element}     [Element above current table]
     */
    private getNearestElementAboveCurrentTable(): Element {
      let returnElement = this.findElementNearCurrentTable("ABOVE");
      if (!returnElement) {
        returnElement = this.firstTabbableElement;
      }
      return returnElement;
    }

    /**
     * Get nearest element below current table.
     * In other words, search forthe element that has focus right after
     * focusing the table.
     * @return {object|element}     [Element below current table]
     */
    private getNearestElementBelowCurrentTable(): Element {
      let returnElement = this.findElementNearCurrentTable("BELOW");
      if (!returnElement) {
        returnElement = this.lastTabbableElement;
      }
      return returnElement;
    }

    /**
     * Find element above or below current table.
     * @param  {string} find        ['ABOVE' or 'BELOW']
     * @return {object|element}     [Element near current table]
     */
    private findElementNearCurrentTable(find: string): Element | null {
      let prevElement: Element | null = null;
      let returnElement: Element | null = null;
      this.allTabbableElements.forEach((value: any) => {
        if (returnElement === null) {
          returnElement = this.compareTableElementOneAndElementTwo(
            find,
            value,
            prevElement
          );
        }
        prevElement = value;
      });
      return returnElement;
    }

    /**
         * Compare two elements, where other element is table element and the other is next to it.
            find can be 'ABOVE' or 'BELOW':
                If 'BELOW' then elemTwo has to be below table.
                If 'ABOVE', then elemTwo has to be above table.
        * @param  {string} find                ['ABOVE' or 'BELOW']
        * @param  {object|element} elemOne     [Table element]
        * @param  {object|element} elemTwo     [Element next to table]
        * @return {object|element}             [description]
        */
    private compareTableElementOneAndElementTwo(
      find: string,
      elemOne: Element,
      elemTwo: Element | null
    ): Element | null {
      let returnElement = null;
      if (find === "ABOVE") {
        if (
          this.isElementOnePartOfCurrTableRowAndElementTwoNextToIt(
            elemTwo,
            elemOne
          )
        ) {
          returnElement = elemOne;
        }
      } else if (find === "BELOW") {
        if (
          this.isElementOnePartOfCurrTableRowAndElementTwoNextToIt(
            elemOne,
            elemTwo
          )
        ) {
          returnElement = elemTwo;
        }
      }
      return returnElement;
    }

    private isElementOnePartOfCurrTableRowAndElementTwoNextToIt(
      elemOne: Element | null,
      elemTwo: Element | null
    ): boolean {
      if (elemOne && elemTwo) {
        if (!this.isElementTableRow(elemTwo)) {
          if (
            this.isElementTableRow(elemOne) &&
            this.isElementEqCurrTableClass(elemOne)
          ) {
            return true;
          }
        }
      }
      return false;
    }

    private isElementTableRow = (elem: Element | undefined | null): boolean => {
      if (!elem) return false;
      if (elem.tagName === "TR") {
        return true;
      }
      return false;
    };

    private isElementEqCurrTableClass = (elem: Element): boolean => {
      if (elem.className === this.currTableClass) {
        return true;
      }
      return false;
    };

    /*
     ************************************************************
     * Table mode finished
     *************************************************************/

    /**
     * Element string can contain many element ids or element names (ES).
     * ES are split by any of these characters: . , ; : \ s.
     * First ES in string is processed first, then if it's not found
     * next ES is processed until some element is found in DOM.
     * If no element is found, an empty string is returned.
     *
     * @param  {string} inStr  [string containing element ids or
     *                           element names]
     * @param  {string} findBy ['query' or 'id']
     */
    private findFirstElementInStringByID(inStr: string): Element | undefined {
      return this.findFirstElementInString(inStr, "id");
    }

    private findFirstElementInStringByQuery(
      inStr: string
    ): Element | undefined {
      return this.findFirstElementInString(inStr, "query");
    }

    private findFirstElementInString(
      inStr: string,
      findBy: string
    ): Element | undefined {
      if (!inStr) {
        return;
      }
      const splitString = this.splitElements(inStr);
      let element;
      for (let i = 0; i < splitString.length; i += 1) {
        if (findBy === "query") {
          element = this.modal.querySelector(splitString[i]);
        } else if (findBy === "id") {
          const document = $document[0] as any;
          element = document.getElementById(splitString[i]);
        }
        if (element) {
          return element;
        }
      }
      return element;
    }

    private getKeyCode = (button: string): boolean => {
      return this.keyCode === this.keyboardButtons[button].keyCode;
    };

    private setFocus = (element: Element | string | undefined): void => {
      if (element instanceof HTMLElement) {
        (<HTMLElement>element).focus();
      }
    };
  }
};

export default samTabbableComponent;
