import * as angular from "angular";

import ActiveLink from "Directives/ActiveLink";
import samCheckLoginid from "Directives/validators/samCheckLoginid";
import samMatchInput from "Directives/validators/samMatchInput";

angular
  .module("serviceWebApp")
  .directive("activeLink", ActiveLink)
  .directive("samCheckLoginid", samCheckLoginid)
  .directive("samMatchInput", samMatchInput);
