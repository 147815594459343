import { UserService, ShipmentService } from "Services";

/**
 * samFavorite marks or unmarks a some arbitrary item as a user's favorite
 * given some reference and a context and persists the changes to database.
 * Said item can for example be a shipment, a sales-quote, a request, etc.
 * that the user wants to keep a close eye on.
 *
 * Arguments/attributes:
 *      @param {boolean} isChecked    A boolean that initializes the
 *          component's favorite-star as filled (favorited) or empty (not favorited).
 *      @param {string} reference    A reference string for the item that is
 *          is being favorited. Used when persisting the item's state.
 *      @param {function}   onToggle     An optional callback to notify
 *          the parent scope when an item is toggled as favorite.
 *
 * TODO: Use a more generic "UserFavorites" service to update/persist the
 *      the items' state (favorited or not) and use the context parameter.
 *      Currently we're using ShipmentService because users can (for now)
 *      only favorite shipments/bookings.
 */
const samFavoriteComponent: ng.IComponentOptions = {
  bindings: {
    isChecked: "<",
    reference: "<",
    onToggle: "&"
  },
  template:
    '<i class="fa" ng-class="$ctrl.favClass" ng-click="::$ctrl.toggleFavorite()"></i>',
  controller: class SamFavoriteController {
    static $inject: string[] = ["$element"];

    // Bindings
    private isChecked: boolean;
    private reference: string;
    private onToggle: Function;

    private currentDOMElement: ng.IRootElementService;
    private updateInProgress: boolean;
    private userId: number;
    private favClass: string;

    constructor($element: ng.IRootElementService) {
      this.currentDOMElement = $element;
    }

    /**
     * Marks or unmarks a given item as a user's favorite.
     */
    toggleFavorite = (): void => {
      if (this.updateInProgress || !this.reference) {
        return;
      }

      this.updateInProgress = true;

      // Send an xhr-request to server to update the state of the item
      // TODO: Swap ShipmentService with a more generic service.
      ShipmentService.toggleFavorite(this.userId, this.reference)
        .then(() => {
          // The component's state is only updated on success
          this.isChecked = !this.isChecked;
          this.notifyParentScope();
        })
        .then(() => {
          this.favClass = this.getFavClass(this.isChecked);
          this.setContainerClass();
          this.updateInProgress = false;
        });
    };

    /**
     * Initializiation for the component.
     */
    $onInit = (): void => {
      // Get the user's id
      const userProfile = UserService.getUserProfile();
      if (!userProfile) return;
      this.userId = userProfile.User.ID;
      if (!this.userId) {
        throw new Error(`UserID is ${this.userId}`);
      }

      this.currentDOMElement.css({
        cursor: "u-colorGreyBlue"
      });

      this.isChecked = this.isChecked === true;
      this.favClass = this.getFavClass(this.isChecked);
      this.setContainerClass();
    };

    /**
     * Get font-awesome classes (icons) that should be applied to the
     * child-element given the current state of the element.
     * @param  {Boolean} isChecked  The current state of the component.
     * @return {string}             Returns the css class(es) to be applied
     *                                      to the element.
     */
    private getFavClass = (isChecked: boolean): string => {
      return isChecked === true ? "fa-star" : "fa-star-o";
    };

    /**
     * Adds or removes the 'isChecked' class to the component's element so
     * clients can apply custom styling to the element and its children
     * when that class is present.
     */
    private setContainerClass = (): void => {
      if (this.isChecked) {
        this.currentDOMElement.addClass("checked");
      } else {
        this.currentDOMElement.removeClass("checked");
      }
    };

    /**
     * Notifies the parent scope of the current state of the component.
     */
    private notifyParentScope = (): void => {
      this.onToggle({
        $reference: this.reference,
        $isChecked: this.isChecked
      });
    };
  }
};

export default samFavoriteComponent;
