import { DomesticService } from "Services";

/**
 * samDomesticStatus component displays status of domestic
 * booking graphically.
 *
 * Arguments/Attributes
 *     status : string
 *         Number value of current booking status.
 *
 */
const samDomesticStatusComponent: ng.IComponentOptions = {
  template: `<div class="BookingStatus" ng-class="::$ctrl.statusClass">
                <div class="centerBorder"></div>
                <span class="circle" title="{{::'LABEL_BOOKED' | translate}}">
                    <span></span>
                </span><span class="circle" title="{{::'LABEL_DATE_RECEIVED' | translate}}">
                    <span></span>
                </span><span class="circle" title="{{::'LABEL_INTRANSPORT' | translate}}">
                    <span></span>
                </span><span class="circle" title="{{::'LABEL_READY' | translate}}">
                    <span></span>
                </span><span class="circle" title="{{::'LABEL_DELIVERED' | translate}}">
                    <span></span>
                </span>
            </div>`,
  bindings: {
    status: "@"
  },
  controller: class SamDomesticStatusController {
    // Bindings
    private status: string;

    private statusClass: string;

    $onInit = (): void => {
      const domStatus = Number(this.status);
      const statusClass = DomesticService.getTileStatusClass(domStatus);
      this.statusClass = statusClass;
    };
  }
};

export default samDomesticStatusComponent;
