import { saveAs } from "file-saver";
import { SamskipNotify } from "Services";
import * as downloadFileTemplate from "Components/samDownloadFile/samDownloadFile.html";

declare var System: any;

const samDownloadFileComponent: ng.IComponentOptions = {
  template: `${downloadFileTemplate}`,
  bindings: {
    buttonIcon: "<",
    buttonTitle: "<",
    buttonPrimary: "<",
    /**
     * Expression that should evaluate to a string or (or a function
     * that returns a string).
     * The evaluated string will be used as the excel document's name.
     * @type {expression}
     */
    fileName: "&",
    fileExtension: "<",
    mimeType: "<",
    /**
     * Expression that should evaluate to a Promise (or a function
     * that returns a Promse).
     * @type {expression}
     */
    serviceCall: "&",
  },
  controller: class SamDownloadFileCtrl {
    public buttonIcon: string;
    public buttonTitle: string;
    private buttonPrimary: boolean;
    public fileName: () => string;
    public fileExtension: string;
    public mimeType: string;
    public serviceCall: () => SamskipPromise<ArrayBuffer>;
    private fetching: boolean;

    constructor() {
      this.fetching = false;

      // Default values

      this.buttonIcon = this.buttonIcon || "fa-download";
      this.buttonPrimary = this.buttonPrimary || false;
      this.fileExtension = this.fileExtension || "pdf";
      this.mimeType = this.mimeType || "application/pdf";
    }

    /**
     * Calls the service provided to the directive in the serviceCall parameter.
     * While the function is waiting for a response the function is locked with the fetching
     * boolean and a spinner is applied to the button.
     */
    public downloadFile = (): void => {
      if (!this.fetching) {
        this.fetching = true;

        this.serviceCall()
          .then(
            (data: ArrayBuffer) => {
              saveAs(
                new Blob([data], {
                  type: this.mimeType,
                }),
                `${this.fileName()}.${this.fileExtension}`
              );
            },
            () => {
              SamskipNotify.displayWarning("ERROR_DOWNLOAD_FILE");
            }
          )
          .then(() => {
            this.fetching = false;
          });
      }
    };
  },
};

export default samDownloadFileComponent;
