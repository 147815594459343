import * as angular from "angular";
import { autorun } from "mobx";
import { TranslationService } from "Services";

const samPluralizeComponent: ng.IComponentOptions = {
  bindings: {
    count: "<",
    key: "<"
  },
  template: '<span ng-bind="$ctrl.text"></span>',
  controller: class PluralizeController {
    static $inject: string[] = ["$scope"];

    // Bindings
    private key?: string;
    private count: number;

    private reactionFn: Function;
    private text: string;

    constructor($scope: any) {
      this.reactionFn = autorun(() => {
        if (TranslationService.isChanged() || TranslationService.isLoaded()) {
          const language = TranslationService.getSelectedLanguage();
          this.changeElement(this.count);
        }
      });
    }

    $onChanges = (changes: any): void => {
      this.changeElement(this.count);
    };

    $onDestroy = () => {
      this.reactionFn();
    };

    /**
     * Handles changing the DOM for the current translation
     * @param count Count
     */
    private changeElement = (count?: number): void => {
      // Get current selected language
      const lang = TranslationService.getSelectedLanguage();
      const translationStrategy = TranslationService.getTranslationStrategy(
        lang
      );
      if (!translationStrategy || this.key === undefined || count === undefined)
        return;

      if (!translationStrategy) {
        console.error("could not find translationStrategy for language:", lang);
      } else {
        this.text = translationStrategy(this.key, count);
      }
    };
  }
};

export default samPluralizeComponent;
