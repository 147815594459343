import * as angular from "angular";
import * as modalTemplate from "./loadingTextModal.html";

const loadingTextModal: ng.IComponentOptions = {
  template: `${modalTemplate}`,
  bindings: {
    modalInstance: "<",
    close: "&",
    dismiss: "&",
    resolve: "<"
  },
  controller: class LoadingTextModalController {
    private resolve: {
      mainText: string;
      icon: string;
    };
    private mainText: string;
    private icon: string;

    $onInit = (): void => {
      this.mainText = this.resolve.mainText;
      this.icon = this.resolve.icon;
    };
  }
};

angular.module("serviceWebApp").component("loadingTextModal", loadingTextModal);
