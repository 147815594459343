import "Bootstrap/init";
import * as angular from "angular";
import * as mobx from "mobx";
import { interceptor } from "SamskipResource/angular";
import interpolator from "Bootstrap/interpolator";
import config from "Bootstrap/config";
import run from "Bootstrap/run";
import TranslationLoader from "Bootstrap/TranslationLoader";
import FeatureToggleRouter from "Components/samFeatureToggle/FeatureToggleRouter";
import { NOTIFICATION_EVENTS } from "Constants/NotificationConstants";

declare var GlobalConfig: GlobalConfig;
declare var window: any;

const debug = process.env.DEBUG;

angular
  .module("serviceWebApp")
  .service("CustomInterpolator", interpolator)
  .factory("TranslationLoader", TranslationLoader.factory())
  .factory("httpInterceptor", () => {
    return interceptor;
  })
  .config(config)
  .run(run);

// MobX strict mode, only allow state mutations through actions
window.mobx = mobx;
mobx.useStrict(true);
if (debug) {
  mobx.spy((event: any) => {
    if (event.type === "action") {
      const className: string = event.object
        ? event.object.constructor.name
        : "undefined";
      const isObject = event.arguments.toString() === "[object Object]";
      const args = isObject ? "" : event.arguments;

      console.log(
        `%c[MobX]: %c${event.name} %c(${className}) %cwith args: %c${args}`,
        "color:#ccc",
        "color:#333;font-weight:bold",
        "color:#666;font-style:italic",
        "color:#ccc",
        "color:#333"
      );

      if (isObject) console.log(event.name, ":", event.arguments);
    }
  });
}
