import RestHelper from "SamskipResource/RestHelper";

class TranslationLoader {
  translationLoader = ($http: ng.IHttpService) => {
    return (options: ng.translate.IStaticFilesLoaderOptions) => {
      const apiUrl = RestHelper.getAPIUrl("AuthAPI");
      const requestUrl = `${apiUrl}/translations/languages/${options.key}`;
      return $http.get(requestUrl).then((response: any) => {
        const data: any[] = response.data;
        const tobj: any = {};
        // Loop through each element in array
        for (let i = 0; i < data.length; i += 1) {
          // Assign translation to the language key
          tobj[data[i].Key] = "";
          if (data[i].Singular != null || data[i].Plural != null) {
            tobj[data[i].Key] = data[i].Singular || data[i].Plural;
            tobj[`${data[i].Key}_PLURAL`] = data[i].Plural || "";
          }
        }

        return tobj;
      });
    };
  };

  static factory = (): any => {
    const instance = new TranslationLoader();
    instance.translationLoader.$inject = ["$http"];

    return instance.translationLoader;
  };
}

export default TranslationLoader;
