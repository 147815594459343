import * as moment from "moment";
import * as pickerTemplate from "Components/samDateTimePicker/samDateTimePicker.html";
import { $rootScope } from "ngimport";

const sameDateTimePickerComponent: ng.IComponentOptions = {
  template: `${pickerTemplate}`,
  bindings: {
    date: "<",
    name: "<",
    title: "<",
    onValueSet: "<",
    onCancel: "<",
    time: "<", // true or false
    maxDate: "<", // datetime
    minDate: "<", // datetime
  },
  controller: class SamDateTimePickerController {
    // Bindings
    private date: Date;
    private name: string;
    private title: string;
    private onValueSet: Function;
    private onCancel: Function;
    private time: number;
    private maxDate: Date;
    private minDate: Date;

    private samDateTime: string;

    constructor() {
      // Validate the incoming date and fail gracefully with a console error,
      // without crashing the app if we receive an invalid date.
      if (this.samDateTime && Date.parse(this.samDateTime)) {
        this.date = new Date(this.samDateTime);
      } else if (!this.samDateTime) {
        this.date = new Date();
      } else if (moment(this.samDateTime, "DD.MM.YYYY HH.mm")) {
        this.date = moment(this.samDateTime, "DD.MM.YYYY HH.mm").toDate();
      }
    }

    save = (): void => {
      const date = this.time
        ? moment(this.date).format("DD.MM.YYYY HH:mm")
        : moment(this.date).format("DD.MM.YYYY");
      this.onValueSet(this.name, date);
      $rootScope.$digest();
    };

    cancel = (): void => {
      if (this.onCancel) {
        this.onCancel(this.name);
      }
      $rootScope.$digest();
    };
  },
};

export default sameDateTimePickerComponent;
