import * as angular from "angular";
import { $compile, $templateCache } from "ngimport";

/**
 * sam-attribute-popover component
 * @param  {String} name           Name of the attribute
 * @param  {String} title          Title of the attribute
 * @param  {String} onAttributeSet Function that is run when attribute value is set
 */
const samAttributePopoverComponent: ng.IComponentOptions = {
  bindings: {
    name: "<",
    title: "<"
  },
  require: {
    samDynamicContent: "^samDynamicContent"
  },
  controller: class SamAttributePopoverCtrl {
    static $inject: string[] = ["$scope", "$element"];

    // Bindings
    private name: string;
    private title: string;
    private samDynamicContent: any;

    private displayValue: string;
    private open: boolean;

    private currentScope: any;
    private currentDOMElement: ng.IRootElementService;

    private readonly prefix: string = "samAttributePopover_";

    constructor($scope: any, $element: ng.IRootElementService) {
      this.currentScope = $scope;
      this.currentDOMElement = $element;
    }

    /**
     * Initialize controller
     */
    $onInit = (): void => {
      const component =
        this.samDynamicContent.attributeMap[this.name] || "sam-input";
      const editable = this.samDynamicContent.editable || false;

      this.displayValue = this.title;

      $templateCache.put(
        this.prefix + this.name,
        `
                <${component}
                    name="'${this.name}'"
                    title="'${this.title}'"
                    on-value-set="$ctrl.onValueSet"
                    on-cancel="$ctrl.onCancel"
                    style="display: block; padding: 15px;">
                </${component}>`
      );

      // Generate appropriate template
      // based on if it's editable or not

      let template = `
            <span
                class="label label-default"
                style="font-size: 10.5px">
                {{$ctrl.displayValue | translate}}
            </span>`;

      if (editable) {
        template = `
                <span
                    class="label label-primary pointer"
                    style="font-size: 10.5px"
                    popover-placement="top"
                    popover-append-to-body="true"
                    popover-is-open="$ctrl.open"
                    uib-popover-template="'${this.prefix}${this.name}'">
                    {{$ctrl.displayValue}}
                </span>`;
      }

      this.currentDOMElement.html(template);
      $compile(<any>this.currentDOMElement.contents())(this.currentScope);

      // Register a watcher on the attribute name
      // if the attributeValues from the parent is present
      // so when value for the attribute
      // is changed, we update all directives
      if (this.samDynamicContent.attributeValues) {
        this.currentScope.$watch(
          <any>angular.bind(this, () => {
            return this.samDynamicContent.attributeValues[this.name];
          }),
          (newVal: any) => {
            if (!newVal) return;
            this.displayValue = newVal;
          }
        );
      }
    };

    /**
     * Function that is passed down to children directives
     * @param  {String} attr  Attribute name
     * @param  {String} value Value of attribute
     */
    onValueSet = (attr: string, value: string): void => {
      if (value === null && this.samDynamicContent.attributeValues[attr]) {
        delete this.samDynamicContent.attributeValues[attr];
      } else if (value !== null) {
        this.samDynamicContent.attributeValues[attr] = value;
      }

      this.open = false;
    };

    onCancel = (): void => {
      this.open = false;
    };
  }
};

export default samAttributePopoverComponent;
