import UserStore from "Stores/UserStore";

const interceptor = {
  responseError: (res: any) => {
    // TODO: Move upper in the hierarchy?
    if (res.status === 401) UserStore.loggedIn(false);

    return Promise.reject(res);
  }
};

export { interceptor };
