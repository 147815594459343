import { $location } from "ngimport";

function activeLink() {
  return {
    restrict: "A",
    link: function link(scope: any, element: ng.IAugmentedJQuery, attrs: any) {
      const clazz = attrs.activeLink;
      const linkPath = attrs.ngHref;
      scope.location = $location;
      scope.$watch("location.path()", (newPath: any) => {
        if (linkPath == null) return;

        const topPath = newPath.substring(
          0,
          newPath.indexOf("/", 1) !== -1
            ? newPath.indexOf("/", 1)
            : newPath.length
        );
        const topLinkPath = linkPath.substring(
          0,
          linkPath.indexOf("/", 1) !== -1
            ? linkPath.indexOf("/", 1)
            : linkPath.length
        );
        if (
          linkPath === newPath ||
          (topPath === topLinkPath && topPath === linkPath)
        ) {
          element.addClass(clazz);
        } else {
          element.removeClass(clazz);
        }
      });
    }
  };
}

export default activeLink;
