import * as angular from "angular";
import * as modalTemplate from "./confirmModal.html";

const ConfirmModal: ng.IComponentOptions = {
  template: `${modalTemplate}`,
  bindings: {
    modalInstance: "<",
    close: "&",
    dismiss: "&",
    resolve: "<"
  },
  controller: class ConfirmationController {
    private close: Function;
    private resolve: any;
    private modalInfo: any;

    $onInit = () => {
      this.modalInfo = this.resolve.modalInfo;
    };

    submit = () => {
      this.close();
    };
  }
};

angular.module("serviceWebApp").component("confirmModal", ConfirmModal);
