import { ShipmentService } from "Services";

/**
 * samShipmentStatus component displays status of shipment
 * booking graphically.
 *
 * Arguments/Attributes
 *     status : string
 *         Number value of current booking status.
 *
 */
const samShipmentStatusComponent: ng.IComponentOptions = {
  bindings: {
    status: "<"
  },
  template: `<div class="BookingStatus" ng-class="::$ctrl.tileStatusClass">
                    <div class="centerBorder"></div>
                    <span class="circle" title="{{::'LABEL_BOOKED' | translate}}">
                        <span></span>
                    </span><span class="circle" title="{{::'LABEL_ATPORTOFLOADING' | translate}}">
                        <span></span>
                    </span><span class="circle" title="{{::'LABEL_INTRANSPORT' | translate}}">
                        <span></span>
                    </span><span class="circle" title="{{::'LABEL_DISCHARGED' | translate}}">
                        <span></span>
                    </span><span class="circle" title="{{$ctrl.tileStatusLabel | translate}}">
                        <span></span>
                    </span>
                </div>`,
  controller: class SamShipmentStatusController {
    // Bindings
    private status: number;

    private tileStatusLabel: string;
    private tileStatusClass: string;

    $onInit = (): void => {
      this.status = Number(this.status);
      this.tileStatusLabel =
        this.status === 6 ? "LABEL_DELIVERED" : "LABEL_READY";
      this.tileStatusClass = this.statusClass(this.status);
    };

    private statusClass = (tileStatus: number): string => {
      return ShipmentService.statusClass(tileStatus);
    };
  }
};

export default samShipmentStatusComponent;
