import { $timeout, $templateCache, $rootScope } from "ngimport";
import * as editableDatePicker from "Components/samEditableDate/samEditablePicker.html";
import * as editableDateTemplate from "Components/samEditableDate/samEditableDate.html";

/** Constants */
const DEFAULT_ICON = "fa-pencil editable-action";
const PENDING_ICON = "fa-spinner fa-pulse";
const SUCCESS_ICON = "fa-check-square";
const ERROR_ICON = "fa-exclamation-triangle";
const EMPTY_CLASS = "empty";
const NBSP_CHAR = "\u00A0";

const samEditableDateComponent: ng.IComponentOptions = {
  template: `${editableDateTemplate}`,
  bindings: {
    editableDate: "<",
    editableFormatter: "&",
    editableSave: "&",
    editableSaveSuccess: "&",
    editableSaveError: "&",
    editableAllowEmpty: "&",
    editableDisabled: "&ngDisabled",
  },
  controller: class SamEditableDateController {
    // Bindings
    private editableDate: string;
    private editableFormatter: Function;
    private editableSave: Function;
    private editableSaveSuccess: Function;
    private editableSaveError: Function;
    private editableAllowEmpty: Function;
    private editableDisabled: Function;

    /**
     * Default formatter for dates.
     * @type {string}       Returns the ISO formatting of dates.
     */
    private readonly defaultFormatters = {
      formatDate: function formatDate(date: Date): string {
        return date.toISOString();
      },
    };

    private date: Date;
    private formatter: Function;
    private iconClass: string = DEFAULT_ICON;
    private viewValue: string;
    private viewValueClass?: string;
    private popoverOpen: boolean;
    private emptyAllowed: boolean;
    private maxDate: number;
    private disabled: boolean;

    constructor() {
      // Add the datepicker template to template cache
      $templateCache.put("samEditablePicker.tpl.html", editableDatePicker);

      /* Initialization */

      this.emptyAllowed = this.editableAllowEmpty() === true || false;
      // Max date today at 23:59:59.000
      this.maxDate = new Date().setHours(23, 59, 59, 0);
      this.formatter = this.getFormatter();
      this.disabled = this.editableDisabled() === true;
    }

    $onInit = () => {
      // Validate the incoming date and fail gracefully with a console error,
      // without crashing the app if we receive an invalid date.
      if (this.editableDate && Date.parse(this.editableDate)) {
        this.date = new Date(this.editableDate);
        this.viewValue = this.formatter();
        this.viewValueClass = undefined;
      } else if (!this.editableDate) {
        this.displayEmpty();
        this.date = new Date();
      } else {
        console.error("Invalid date:", this.editableDate);
      }
    };

    /**
     * Runs the provided method to save the edited date via the parent-scope
     * function passed in via the 'editable-save' attribute
     * @return {[type]} [description]
     */
    save = (): void => {
      this.saveDate(this.date);
      $rootScope.$digest();
    };

    cancel = (): void => {
      // TODO: Reset to initial values if changes have been made
      this.popoverOpen = false;
      $rootScope.$digest();
    };

    clearDate = (): void => {
      if (!this.emptyAllowed) {
        return;
      }
      this.saveDate(null);
    };

    /**
     * Checks is a save callback is defined by the parent scope and applies
     * and calls that function. If the callback returns a promise we'll
     * display some animation indicating that there is some work being done
     * server-side and call the parent scope's methods for success or error
     * if defined.
     * @param  {Date}   newDate     The modified date.
     * @return {[type]}         [description]
     */
    private saveDate = (newDate: Date | null): void => {
      let savePromise;
      this.iconClass = PENDING_ICON;

      const returnedDate = newDate
        ? this.defaultFormatters.formatDate(newDate)
        : null;

      // Call the parent-scope save method
      if (this.editableSave) {
        savePromise = this.editableSave({
          newDate: returnedDate,
        });
      }

      if (savePromise instanceof Promise) {
        savePromise.then(
          (data: any) => {
            this.editableSaveSuccess({
              data,
              newDate: returnedDate,
            });

            // Hide the popover
            this.popoverOpen = false;

            // Indicate success by changing icons
            this.setTemporaryIconClass(SUCCESS_ICON);

            if (newDate === null) {
              this.displayEmpty();
            } else {
              // Set viewValue the this.editableDate as the new date
              this.viewValue = this.formatter();
              this.viewValueClass = undefined;
            }
          },
          (data: any) => {
            this.editableSaveError({
              error: data,
            });

            // Indicate error by changing icons
            this.setTemporaryIconClass(ERROR_ICON);
          }
        );
      }
    };

    /**
     * Temporarily sets the vm.iconClass property to the given 'iconClass'
     * argument and then resets it to DEFAULT_ICON.
     * @param {string} iconClass    The class to apply to 'vm.iconClass'
     */
    private setTemporaryIconClass = (iconClass: string): void => {
      this.iconClass = iconClass;
      $timeout(() => {
        this.iconClass = DEFAULT_ICON;
      }, 1500);
    };

    /**
     * Gets the formatting function from the parent scope if defined.
     * If the parent scope has not defined a formatting function, we'll
     * default to the default formatter.
     * @return {function}   The formatting function to apply to the viewValue.
     */
    private getFormatter = (): Function => {
      if (
        this.editableFormatter &&
        typeof this.editableFormatter() === "string"
      ) {
        this.formatter = this.editableFormatter;
      } else {
        console.error("Default formatter not implemented");
      }
      return this.formatter;
    };
    /**
     * Applies the placeholder class when the viewValue is some empty
     * value so that the pencil doesn't seem out of place.
     */
    private displayEmpty = (): void => {
      this.viewValueClass = EMPTY_CLASS;
      this.viewValue = NBSP_CHAR;
    };
  },
};

export default samEditableDateComponent;
