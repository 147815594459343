import * as angular from "angular";

import {
  uniqueFilter,
  uniqueValueFilter,
  thousandFilter,
  startFromFilter,
  nullFixFilter,
  simpleSort,
  simpleSortDesc,
  stringContainsFilter,
  customNumberFilter,
  latinizedTypeaheadHighlightFilter
} from "Filters/filters";

angular
  .module("serviceWebApp")
  .filter("unique", uniqueFilter)
  .filter("uniqueValue", uniqueValueFilter)
  .filter("thousand", thousandFilter)
  .filter("startFrom", startFromFilter)
  .filter("simpleSort", simpleSort)
  .filter("simpleSortDesc", simpleSortDesc)
  .filter("stringContains", stringContainsFilter)
  .filter("nullFix", nullFixFilter)
  .filter("customNumber", customNumberFilter)
  .filter("latinizedTypeaheadHighlight", latinizedTypeaheadHighlightFilter);
