/**
 * samMatchInput directive can be used to make sure that two input fields have
 * the same input values, e.g. when users are required to confirm their
 * chosen password by entering their password twice.
 * USAGE: <input type="text" sam-match-input="the_first_password_field" />
 * E.g. When entering the password for the second time, the directive will
 * watch the first password-field to make sure that both fields have the
 * exact same input. If changes are made to either field, and the inputs
 * are inconsistent, Angular's $validators pipeline will return an error,
 * which we can then handle by say, displaying the appropriate error messages.
 * @return {[type]} [description]
 */
function samMatchInput() {
  return {
    require: "ngModel",
    restrict: "A",
    scope: {
      inputToMatch: "=samMatchInput"
    },
    link: function link(
      scope: any,
      element: ng.IAugmentedJQuery,
      attrs: ng.IAttributes,
      ctrl: ng.IController
    ) {
      ctrl.$validators.inputMismatch = function inputMismatch(
        modelValue: string
      ) {
        // === comparison returns 'false' when 'null' === undefined.
        // That's why we need to do the first check
        return (
          modelValue === scope.inputToMatch.$viewValue ||
          (modelValue == null && scope.inputToMatch.$viewValue == null)
        );
      };

      scope.$watch("inputToMatch.$viewValue", () => {
        ctrl.$validate();
      });
    }
  };
}

export default samMatchInput;
