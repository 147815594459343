/**
 * <sam-items-per-page> component
 * It's to be used with pagination directive in UI Bootstrap
 * It sets the 'itemsPerPage' value in parent scope and emits an event when
 * the value has been changed, so the parent can do some stuff (e.g. animations/loading/etc.)
 * INPUTS:
 *  items-displayed: An array with numbers to display, e.g. [10, 40, 80, 100]
 *  items-per-page:  The name of the 'itemsPerPage' variable in parent scope (which pagination uses)
 */
const samItemsPerPageComponent: ng.IComponentOptions = {
  bindings: {
    itemsDisplayed: "=",
    itemsPerPage: "="
  },
  template: `<div class="samItemsPerPage">
                    <div class="samItemsPerPageText" data-translate="TEXT_RECORDS_ON_PAGE"></div>
                    <ul>
                        <li data-ng-repeat="i in ::$ctrl.itemsDisplayed"
                            data-ng-click="::$ctrl.setItemsPerPage(i)">
                                <span data-ng-bind="::i" data-ng-class="{ 'selected': i == $ctrl.itemsPerPage }">
                                </span>
                        </li>
                    </ul>
                </div>`,
  controller: class SamItemsPerPageController {
    static $inject: string[] = ["$scope"];

    // Bindings
    private itemsDisplayed: any[];
    private itemsPerPage: number;

    private currentScope: any;

    constructor($scope: any) {
      this.currentScope = $scope;
    }

    /**
     * Sets items per page
     * @param {Number} items
     */
    setItemsPerPage = (items: number): void => {
      this.itemsPerPage = items;

      // Emit an event noticing the parent scope about the change
      this.currentScope.$emit("itemsPerPageChanged", items);
    };
  }
};

export default samItemsPerPageComponent;
