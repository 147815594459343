/**
 * samSavingComponent -- Display text and icon related to saving information in a single line.
 *
 * Arguments/Attributes
 *  saving-text     : {string}  Text to display. Can be translation key.
 *  saving-icon     : {string}  Icon to display.
 */
const samSavingComponent: ng.IComponentOptions = {
  bindings: {
    savingText: "@savingText",
    savingAdditionalText: "@savingAdditionalText",
    savingIcon: "@savingIcon"
  },
  template: `<div class="u-italicFontStyle" style="opacity: 0.9;">
                    <i ng-class="$ctrl.displayIcon"
                        ng-show="$ctrl.displayIcon !== ''">
                    </i>
                    {{$ctrl.displayText}}
                    <i ng-if="$ctrl.displayAdditionalText">
                    : {{$ctrl.displayAdditionalText}}
                    </i>
                </div>`,
  controller: class SamSavingController {
    // Bindings
    private savingText: string;
    private savingAdditionalText: string;
    private savingIcon: string;

    private displayText: string;
    private displayAdditionalText: string;
    private displayIcon: string;

    $onChanges = (changesObj: any): void => {
      let newIcon = this.savingIcon;
      let newAdditionalText = this.savingAdditionalText;
      let newText = this.savingText;

      if (changesObj.savingText != null) {
        newText = changesObj.savingText.currentValue;
      }
      if (changesObj.savingAdditionalText != null) {
        newAdditionalText = changesObj.savingAdditionalText.currentValue;
      }
      if (changesObj.savingIcon != null) {
        newIcon = changesObj.savingIcon.currentValue;
      }

      this.processNewState(newText, newAdditionalText, newIcon);
    };

    /**
     * Validates state and displays correct information
     * to user.
     */
    private processNewState = (
      savingText: string,
      savingAdditionalText: string,
      savingIcon: string
    ): void => {
      this.displayText = savingText;
      this.displayAdditionalText = savingAdditionalText;
      this.displayIcon = savingIcon;
    };
  }
};

export default samSavingComponent;
