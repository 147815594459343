import { $interpolate } from "ngimport";
import { $translateDefaultInterpolation } from "Bootstrap/angular";

/**
 * CustomInterpolatorService is a custom-built interpolator that is used by
 * angular-translate's $translateProvider. It enables us to insert special
 * characters at predefined markers when encountered in translations, e.g.
 * the HTML soft-hyphen (&shy;) to break words at predetermined places as
 * well as do other fancy stuff.
 * For more info see https://angular-translate.github.io/docs/#/guide/15_custom-interpolators
 * @param {Object}      $translateDefaultInterpolation The default
 *                                      interpolator used by angular-translate.
 */
class CustomInterpolator {
  private $locale: any;

  setLocale = (locale: string) => {
    this.$locale = locale;
  };

  getInterpolationIdentifier = (): string => {
    return "custom";
  };

  /**
   * This is where the magic happens. If a translation contains the '^'
   * character, every occurance of it is replaced by the soft-hyphen
   * (HTML: &shy; Unicode: \u00ad) and the translated word will break at
   * those points when it no longer fits inside its container.
   * @param  {String} str                 The original translated string.
   * @param  {Object} interpolateParams   An object of interpolation
   *                                      parameters used for variable
   *                                      replacement.
   * @param  {String} sanitizeStrategy    The sanitation-strategy
   *                                      currently being used.
   * @return {String}                     Returns a customly interpolated
   *                                      string.
   */
  interpolate = (
    str: string,
    interpolateParams: any,
    sanitizeStrategy?: any
  ) => {
    let replaced;

    // Replace all occurances of '^' if present.
    if (str && str.indexOf("^") > 0) {
      replaced = str.replace(/\^/g, "\u00ad");
      return $interpolate(replaced)(interpolateParams);
    }

    // Default to angular-translate's default interpolator
    return $translateDefaultInterpolation.interpolate(str, interpolateParams);
  };
}

export default CustomInterpolator;
