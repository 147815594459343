import * as angular from "angular";
import * as moment from "moment";
import { $compile, $templateRequest, $document } from "ngimport";
import { languages, ShipmentRegistryService } from "Services";
import { ArrayUtilities } from "Utilities";
import * as popupTemplate from "Components/samVoyagePicker/voyagePickerPopup.html";

function voyagePickerController(
  $scope: any,
  $element: ng.IRootElementService,
  $attrs: ng.IAttributes,
  $uibPosition: ng.ui.bootstrap.IPositionService
) {
  const vm = this;

  let pickerPopup: JQLite;

  // default number of voyages displayed in the list
  const listSize = 5;
  let skipCount = 0;

  /**
   * Called when the user clicks the 'load more voyages' button.
   */
  vm.loadMoreVoyages = function loadMoreVoyages() {
    updateVoyageList(vm.showFetchMoreButton === true);
  };

  /**
   * Called when the user selects a voyage from the popup's list of voyages.
   * Assigns selected voyage to the parent scope's model attached to the
   * component via ngModelController and closes the popup.
   * @param  {object} voyage      The voyage selected by the user
   */
  vm.selectVoyage = function selectVoyage(voyage: any): void {
    if (vm.voyageNotSelectable(voyage.CutoffDate, voyage.ETA).notSelectable) {
      return;
    }
    const sanitizedVoy = sanitizeOutput(voyage);
    vm.voyageSelected({
      voyage: sanitizedVoy
    });
    closePicker();
  };

  vm.selectVoyageByIndex = function selectVoyageByIndex(index: number): any {
    // If the selected index is an out-of-range value compared to
    // the size of the vm.voyageList array, we do nothing on purpose
    if (index < 0 || index >= vm.voyageList.length) {
      return;
    }
    vm.selectVoyage(vm.voyageList[index]);
  };

  vm.cancelEdit = function cancelEdit(): void {
    closePicker();
  };

  vm.voyageNotSelectable = function voyageNotSelectable(
    cutoffDate: string,
    ETA: string
  ): { notSelectable: boolean; reason: string } {
    return vm.collectionDate !== undefined ?? vm.deliveryDate !== undefined
      ? {
          notSelectable:
            Date.parse(cutoffDate) < vm.collectionDate ||
            Date.parse(ETA) > vm.deliveryDate,
          reason:
            Date.parse(cutoffDate) < vm.collectionDate
              ? `${languages(
                  "INFO_HOVER_COLLECTION_TIME"
                )} ${$scope.formatDateTime(
                  vm.collectionDate,
                  "DD.MM.YYYY HH:mm"
                )}`
              : `${languages(
                  "INFO_HOVER_DELIVERY_TIME"
                )} ${$scope.formatDateTime(
                  vm.deliveryDate,
                  "DD.MM.YYYY HH:mm"
                )}`,
        }
      : vm.collectionDate
      ? {
          notSelectable: Date.parse(cutoffDate) < vm.collectionDate,
          reason: `${languages(
            "INFO_HOVER_COLLECTION_TIME"
          )} ${$scope.formatDateTime(vm.collectionDate, "DD.MM.YYYY HH:mm")}`,
        }
      : { notSelectable: false, reason: "" } || vm.deliveryDate
      ? {
          notSelectable: Date.parse(ETA) > vm.deliveryDate,
          reason: `${languages(
            "INFO_HOVER_DELIVERY_TIME"
          )} ${$scope.formatDateTime(vm.deliveryDate, "DD.MM.YYYY HH:mm")}`,
        }
      : { notSelectable: false, reason: "" };
  };

  $scope.formatDateTime = function formatDateTime(
    dateTimeStr: string,
    dateFormat: string
  ): string {
    const m = moment(dateTimeStr);
    return m.isValid() ? m.format(dateFormat || "DD.MM.YY - HH:mm:ss") : "";
  };

  // Watch for changes to the isOpen property
  $scope.$watch(
    <any>angular.bind(vm, () => {
      return vm.isOpen;
    }),
    (newVal: any) => {
      if (newVal === true) {
        openPicker();
      } else if (newVal === false) {
        closePicker();
      }
    }
  );

  // Watch for changes to the POL property
  $scope.$watch(
    <any>angular.bind(vm, () => {
      return vm.POL;
    }),
    (newVal: any) => {
      if (newVal && !vm.voyagesLoading) {
        updateVoyageList();
      } else {
        vm.voyageList = undefined;
      }
    }
  );

  // Watch for changes to the POD property
  $scope.$watch(
    <any>angular.bind(vm, () => {
      return vm.POD;
    }),
    (newVal: any) => {
      if (newVal && !vm.voyagesLoading) {
        updateVoyageList();
      } else {
        vm.voyageList = undefined;
      }
    }
  );

  // Ensure that the popup is removed when the comonent's scope is
  // destroyed. E.g. when moving between states.
  $scope.$on("$destroy", () => {
    if (pickerPopup) {
      pickerPopup.remove();
    }
  });

  /**
   * Creates a popup with a list of voyages, appends it to the body
   * element and positions it below the parent element
   */
  function openPicker(): void {
    if ($attrs.disabled === true) {
      vm.isOpen = false;
      return;
    }
    if (!vm.isOpen) {
      return;
    }
    // get the parent element's position (and dimensions)
    const pos = $uibPosition.offset($element);

    // Instead of relying on an extra directive just to load and show
    // the voyagePicker popup, we use angular built-in $templateRequest
    // service to load (and automatically cache) the popup template
    pickerPopup = $compile(
      angular.element(popupTemplate.toString()).css({
        position: "absolute",
        top: `${(pos.top || 0) - 5}px`,
        left: `${(pos.left || 0) - 5}px`
      })
    )($scope);
    $document.find("body").append(pickerPopup);
  }

  /**
   * Removes the popup element and re-initializes the voyagelist data
   */
  function closePicker(): void {
    vm.isOpen = false;
    if (pickerPopup) {
      pickerPopup.remove();
    }
    // If skipCount is greater than 0, re-initialize the voyagelist
    if (skipCount > 0) {
      updateVoyageList();
    }
  }

  /**
   * Gets a list of voyages from server based on the selected POL
   * and POD and updates the popup's displayed list.
   * @param  {bool} incrementSkipCount    If this is a true, skipCount is
   *      incremented to get the next 'page' of voyage results. Otherwise,
   *      the voyagelist is initialized to the first 'page'.
   */
  function updateVoyageList(incrementSkipCount: boolean = false): void {
    let noResults: boolean;
    let canGetMore: boolean;
    let onLastPage: boolean;

    if (!vm.POL || !vm.POD) {
      return;
    }
    // Only issue requests to the server when POL and POD
    // are distinct values
    if (vm.POL !== vm.POD) {
      // Increment or initialize skipCount
      skipCount = incrementSkipCount ? listSize + skipCount : 0;

      // Display loading-spinner
      vm.voyagesLoading = true;

      // Get data from server
      ShipmentRegistryService.getNextVoyages(
        vm.POL,
        vm.POD,
        listSize,
        skipCount
      )
        .then(
          (data: any) => {
            const n = data.length;
            if (n >= listSize) {
              canGetMore = true;
            } else if (n > 0 && n < listSize) {
              onLastPage = true;
            } else {
              noResults = true;
            }

            vm.showFetchMoreButton = canGetMore;
            vm.showResetButton = vm.voyageList && (noResults || onLastPage);
            vm.noMatchingResults = !vm.voyageList && noResults;

            // Manipulate the data before rendering
            if (n > 0) {
              vm.voyageList = handleVoyageData(data);
            } else {
              vm.voyageList = undefined;
              skipCount = 0;
            }
          },
          function error(err: any) {
            console.error(err);
          }
        )
        .then(() => {
          // Hide loading-spinner
          vm.voyagesLoading = false;
        });
    }
  }

  /**
   * Formats a datetime string-value
   * @param  {string} date    A valid datetime string
   * @return {string}         Formatted date string e.g. 31.12.99
   */
  function formatDateShort(date: string): string {
    return moment(date).format("DD.MM.YY");
  }

  /**
   * Formats a datetime string-value with time in 24-hour notation
   * @param  {string} date    A valid datetime string
   * @return {string}         Formatted date string e.g. 31.12.99 23:59
   */
  function formatDateTimeShort(date: string): string {
    return moment(date).format("DD.MM.YY HH:mm");
  }

  /**
   * Adds more human-readable properties to the data returned from
   * server before rendering it in the popup's list.
   * @param  {Array} voyageList   The list of voyages as returned from server
   * @return {Array}              Returns the same list with added properties
   *                                      for readability.
   */
  function handleVoyageData(voyageList: any[]): any[] {
    voyageList.forEach(it => {
      it._ETD = formatDateShort(it.ETD);
      it._ETA = formatDateShort(it.ETA);
      it._cutoff = formatDateTimeShort(it.CutoffDate);

      // Assign a unique id to each voyage to help with ng-repeat
      // 'track by' because the list is always of fixed length
      it._uid = Math.random()
        .toString(36)
        .substring(2, 15);

      // Display-friendly transit-time, e.g. '2 dagar', '9 hours', etc.
      it._transitTime = moment(it.ETD).from(moment(it.ETA), true);
    });

    const sortedArray = ArrayUtilities.sortBy(voyageList, (it: any) => {
      // Fail-safe if the server returns an unordered list
      return moment(it.CutoffDate).unix();
    });

    return sortedArray;
  }

  /**
   * Return a sanitized object with properties we only
   * want to expose
   * @param  {object} voyage  Object to 'clean up'
   * @return {object}         Returns the same object without any extra
   *                          properties.
   */
  function sanitizeOutput(voyage: any): any {
    return {
      VoyageReference: voyage.VoyageReference,
      CutoffDate: voyage.CutoffDate,
      POL: voyage.POL,
      POD: voyage.POD,
      POT: voyage.POT,
      ETD: voyage.ETD,
      ETA: voyage.ETA,
      POL_VISIT_NR: voyage.POLVisitNumber
    };
  }
}

/**
 * samVoyagepickerComponent -- a custom component that shows the next
 * scheduled voyages given some pair of loading, and discharge ports.
 * Arguments/attributes:
 *     POL     : string -- One-way binding to the port of load, e.g. 'ISREY'
 *     POD: string -- One-way binding to the port of discharge,
 *         e.g. 'NLRTM'
 *     isOpen : boolean -- Two-way binding because both the parent-scope and
 *         the component should able to control this.
 *     voyagesLoading: bool -- Binding to a value set by the component when
 *         it's loading data from some remote source so the parent scope
 *         can take some actions when this is happening
 *     voyageSelected: expression(function) -- A callback that is called when
 *         the user selects a voyage from the results.
 */
const samVoyagepickerComponent: ng.IComponentOptions = {
  controller: voyagePickerController,
  controllerAs: "$ctrl",
  require: {
    ngModelCtrl: "ngModel"
  },
  bindings: {
    POL: "<voyagepickerPol",
    POD: "<voyagepickerPod",
    isOpen: "=voyagepickerIsOpen",
    voyagesLoading: "=voyagepickerLoading",
    voyageSelected: "&voyagepickerOnSelect",
    collectionDate: "<voyagepickerCollectionDate",
    deliveryDate: "<voyagepickerDeliveryDate"
  }
};

voyagePickerController.$inject = [
  "$scope",
  "$element",
  "$attrs",
  "$uibPosition"
];
export default samVoyagepickerComponent;
