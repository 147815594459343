import { $compile } from "ngimport";

/**
 * sam-dynamic-content component
 * @param  {String} editable          If the label should be editable
 * @param  {Model}  content           Content to make dynamic
 * @param  {Model} requiredAttributes Array with required attributes
 * @param  {Model} attributeValues    Attribute values
 */
const samDynamicContentComponent: ng.IComponentOptions = {
  bindings: {
    editable: "<",
    content: "<",
    requiredAttributes: "<",
    attributeValues: "<"
  },
  controller: class SamDynamicContentController {
    static $inject: string[] = ["$scope", "$element"];

    // Bindings
    private editable: boolean;
    private content: string;
    private requiredAttributes: any[];
    private attributeValues: string[];

    private currentScope: any;
    private currentDOMElement: ng.IRootElementService;

    private readonly attributeMap = {
      DATE: "sam-date-time"
    };

    constructor($scope: any, $element: ng.IRootElementService) {
      this.currentScope = $scope;
      this.currentDOMElement = $element;
    }

    /**
     * Initialize controller
     * Go through each required attribute
     * and create a Samskip attribute popover
     */
    $onInit = (): void => {
      let template = this.content;
      this.requiredAttributes.forEach((key: any) => {
        const regex = new RegExp(`{{${key.Name}}}`, "g");
        template = template.replace(
          regex,
          `<sam-attribute-popover name="'${key.Name}'" title="'${key.Title}'"></sam-attribute-popover>`
        );
      });

      // Add a div wrapper around the template
      template = `<div style="white-space: pre-line">${template}</div>`;

      this.currentDOMElement.html(template);
      $compile(<any>this.currentDOMElement.contents())(this.currentScope);
    };
  }
};

export default samDynamicContentComponent;
