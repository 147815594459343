import { Number } from "es6-shim";

/**
 * samInputNumberComponent -- a wrapper around input field that
 * handles positive and negative integer- and fraction numbers and allows
 * users to use either ',' or '.' as a decimal point.
 * @type {Object}
 * Arguments/attributes:
 *  ng-model    : {Object}  The model that the component's value is bound to.
 *  min         : {string}  Minimum allowed input value (optional)
 *  max         : {string}  Maximum allowed input value (optional)
 *  input-styles: {string}  Styles to apply to input field (optional)
 *  decimal     : {bool}    Determines if the user should be allowed to enter
 *                          decimals. If min or max are set to a decimal-
 *                          value, decimals are allowed, otherwise it
 *                          defaults to false (optional).
 *  ng-disabled : {bool}    Should the component be disabled? (optional)
 *  ng-required : {bool}    Is the value required? (optional)
 */
const samInputNumberComponent: ng.IComponentOptions = {
  bindings: {
    min: "@",
    max: "@",
    allowDecimal: "&decimal",
    styles: "@inputStyles",
    disabled: "=?ngDisabled",
    required: "=?ngRequired",
    placeholder: "@"
  },
  template: `<input
                    ng-class="$ctrl.styles"
                    type="text"
                    ng-model="$ctrl.inputValue"
                    ng-change="$ctrl.handleInput()"
                    ng-keydown="$ctrl.handleKeyDown($event)"
                    ng-disabled="$ctrl.disabled"
                    ng-required="$ctrl.required"
                    placeholder="{{$ctrl.placeholder}}">
                </input>`,
  require: {
    ngModelCtrl: "ngModel"
  },
  controller: class SamInputNumberController {
    // Bindings
    private min?: number;
    private max?: number;
    private allowDecimal: Function;
    private styles: string;
    private disabled: boolean;
    private required: boolean;
    private placeholder: string;

    private ngModelCtrl: any;
    private readonly allowedStartCharsTest = /^-?[,.]?$/;
    private inputValue: string;
    private prevModelValue: any;
    private prevValue: any;

    /**
     * Component initialization
     */
    $onInit = (): void => {
      this.min = this.parseNumber(this.min);
      this.max = this.parseNumber(this.max);
    };

    /**
     * Watch for outside changes to the component's bindings, min/max in
     * particular.
     * @param  {Object} changes  A hash whose keys are the names of the
     *                           bound properties that have changed
     */
    $onChanges = (changes: any): void => {
      if (changes.min) {
        this.min = this.parseNumber(changes.min.currentValue);
      }
      if (changes.max) {
        this.max = this.parseNumber(changes.max.currentValue);
      }
      this.validateInput();
    };

    /**
     * $doCheck is called in each turn of the digest-cycle. This is needed
     * because ngModelCtrl's $modelValue may not have been initialized
     * properly when the component's $onInit. Furthermore, changes to
     * $modelValue aren't registered in the $onChanges hook and we need to
     * be aware of outside changes to the model, e.g. when $modelValue is
     * reset/cleared or updated by the parent-context.
     */
    $doCheck = (): void => {
      this.checkNgModel();
    };

    /**
     * Event-handler for when the user changes the component's input
     */
    handleInput = (): void => {
      // Set the model as initialized to stop checking during $doCheck
      this.validateInput();
    };

    /**
     * Event-handler for keypresses. Added only to ignore hits on the
     * space-bar.
     * @param  {Event} $event
     */
    handleKeyDown = ($event: any): void => {
      const spaceBar = 32;
      if ($event.keyCode === spaceBar) {
        $event.preventDefault();
        $event.stopPropagation();
      }
    };

    /**
     * Checks if this.ngModelCtrl.$modelValue has been changed by the parent
     * context and triggers validation and an update of the internal
     * this.inputValue if the $modelValue has changed.
     */
    private checkNgModel = (): void => {
      if (this.prevModelValue !== this.ngModelCtrl.$modelValue) {
        this.prevModelValue = this.ngModelCtrl.$modelValue;

        this.inputValue = (this.ngModelCtrl.$modelValue || "").toString();

        this.validateInput();
      }
    };

    /**
     * Validates the input as the user interacts with the component.
     */
    private validateInput = (): void => {
      let currentValue;

      /*
       * Initially we'll assume that the model is valid. If any of the
       * checks below fail, it'll be invalidated.
       */
      this.setValid();

      // The input was just cleared -- $setViewValue sets validity based
      // on if the value is model is required or not.
      if (!this.inputValue) {
        this.prevValue = undefined;
        this.ngModelCtrl.$setViewValue(this.prevValue);
        return;
      }

      // If the value is one of the allowed start-values, set invalid,
      // skip any further checks and  wait for more input.
      if (this.allowedStartCharsTest.test(this.inputValue)) {
        this.setInvalid("number");
        return;
      }

      this.inputValue = this.trimLeadingZeroes(this.inputValue);

      // Cast the input-string to Number
      currentValue = Number(this.inputValue.replace(",", "."));

      // If currentValue is not a valid number, set the input's value to
      // the last valid value that was entered.
      if (!Number.isFinite(currentValue)) {
        this.inputValue = this.prevValue;
        return;
      }

      // If only integers are allowed, remove any decimal places
      if (!this.decimalAllowed()) {
        currentValue = Math.floor(currentValue);
        this.inputValue = currentValue.toString();
      }

      // Check the number against the minimum if that is defined
      if (
        (this.min || this.min === 0) &&
        Number.isFinite(this.min) &&
        currentValue < this.min
      ) {
        this.setInvalid("min");
        return;
      }

      // Check the number against the maximum if that is defined
      if (this.max && Number.isFinite(this.max) && currentValue > this.max) {
        this.setInvalid("max");
        return;
      }

      // Everything checks out.
      this.ngModelCtrl.$setViewValue(currentValue);
      this.setValid();
      this.prevModelValue = currentValue;
      this.prevValue = this.inputValue;
    };

    /**
     * Checks if fractions are allowed.
     * @return {bool}   Returns true if 'allow-fractions' is explicitly set
     *                  or either of 'min' or 'max' contains a decimal point
     */
    private decimalAllowed = (): boolean => {
      const decimalPattern = /^-?\d*\.\d+$/;
      const minMatch =
        this.min !== undefined && decimalPattern.test(this.min.toString());
      const maxMatch =
        this.max !== undefined && decimalPattern.test(this.max.toString());
      return this.allowDecimal() === true || minMatch || maxMatch;
    };

    /**
     * Removes extra zeroes (more than one occurance of '0') from the front
     * of the input-string. E.g. '00000.0021230' becomes '0.002123'.
     * @param  {String} str The string to strip zeroes from.
     * @return {String}     Returns a new string without leading zeroes.
     */
    private trimLeadingZeroes = (str: string): string => {
      const leadingZerosTest = /^(-?)0+(\d+[,.]?\d*)$/.exec(str);
      if (leadingZerosTest) {
        return leadingZerosTest[1] + leadingZerosTest[2];
      }
      return str;
    };

    /**
     * Sets the model's validity via ngModelController
     * @param {String} key  Name of the validator to set valid. Is assigned
     *                      to the model's $error object. If key is a falsy
     *                      value, each of the validators that we've
     *                      invalidated will be returned to its initial
     *                      valid-state.
     */
    private setValid = (key?: string): void => {
      if (key) {
        this.ngModelCtrl.$setValidity(key, true);
      } else {
        ["min", "max", "number"].forEach((it: any) => {
          this.ngModelCtrl.$setValidity(it, true);
        });
      }
    };

    /**
     * Sets a single validator for the model as invalid via ngModelController.
     * @param {String} key Name of the validator to set invalid.
     */
    private setInvalid = (key: string): void => {
      if (!key) {
        return;
      }
      this.ngModelCtrl.$setValidity(key, false);
    };

    /**
     * Parses a string to a number.
     * @param  {String} value The string to parse.
     * @return {Number}       Returns a Number if value converts to a valid
     *                        finite Number (exludes NaN, Inifinity, etc.),
     *                        else undefined.
     */
    private parseNumber = (value?: string | number): number | undefined => {
      if (!value) return;

      let asNumber: number;
      if (typeof value === "number") {
        // value is already of type Number
        asNumber = value;
      } else {
        // value is a non-empty string (needed because Number('') === 0)
        asNumber = Number(value);
      }
      return Number.isFinite(asNumber) ? asNumber : undefined;
    };
  }
};

export default samInputNumberComponent;
