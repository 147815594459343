import * as angular from "angular";

/**
 * This is a simple component whose only purpose in life is to ensure
 * consistent look for when users want to select all items in some list,
 * e.g. list of shipments, list of warehouse receptions, etc. All it does is
 * render two buttons; select-all and select-none and provide callbacks for
 * when those buttons are clicked to the scope that uses the component.
 * It does NOT (and should not) manipulate the actual list-data in any
 * way, that is left to the scope that provides/owns the data (adhering to
 * this guide: https://docs.angularjs.org/guide/component).
 *
 * Arguments/attributes:
 *      @param {string}     onToggle    Callback to notify the parent
 *          scope that that the multiselection has been toggled by the component.
 *      @param {expresion}  isChecked   Optional expression that is watched
 *          and determines whether or not to show the checkmark (the
 *          component's internal isChecked state) and has a watcher on it.
 */
const samMultiselectComponent: ng.IComponentOptions = {
  bindings: {
    onToggle: "&",
    isChecked: "<"
  },
  template: `<input type="checkbox" class="css-checkbox"
                    id="{{::$ctrl.uniqueId}}"
                    ng-click="::$ctrl.toggleSelection()"
                    ng-model="$ctrl.isChecked">
                <label for="{{::$ctrl.uniqueId}}"
                    name="{{::$ctrl.uniqueId}}"
                    class="css-label lite-gray-check">
                </label>`,
  controller: class SamMultiSelectController {
    static $inject: string[] = ["$scope"];

    // Bindings
    private onToggle: Function;
    private isChecked: boolean;

    private currentScope: any;
    private uniqueId: string;

    constructor($scope: any) {
      this.currentScope = $scope;
    }

    /** Component initialization */
    $onInit = (): void => {
      this.uniqueId = this.generateUniqueId();

      // Items can be selected and deselected from the outer scope and
      // manually by the user and to keep the samMultiselect component in
      // sync, we need to add a watcher for the isChecked expression that
      // is defined in the outer scope.
      const offCheckedWatcher = this.currentScope.$watch(
        <any>angular.bind(this, () => {
          return Boolean(this.isChecked);
        }),
        (newvalue: boolean, oldvalue: boolean) => {
          if (newvalue !== oldvalue) {
            this.isCheckedChangedByParentScope(newvalue);
          }
        }
      );
      // Deregister the watch on changes to the incoming isChecked expression
      this.currentScope.$on("$destroy", offCheckedWatcher);
    };

    /**
     * Notifies the outer scope that changes have been made from with in the
     * component. This is not and should not run when the state is changed
     * from the outer scope.
     */
    toggleSelection = (): void => {
      this.onToggle({
        $checked: this.isChecked
      });
    };

    /**
     * Handler for when the component's state has been changed by the
     * outer scope. Is not run when the state is toggled from within the
     * component.
     * @param  {Boolean}  newvalue  Updated state of the component.
     */
    private isCheckedChangedByParentScope = (newvalue: boolean): void => {
      if (this.isChecked !== newvalue) {
        this.isChecked = newvalue === true;
      }
    };

    /**
     * Generates a psuedo-random integer for the component's input and label
     * elements so that we can use 'label for="[generated id]"' in the html
     * @return {string} Returns a random id
     */
    private generateUniqueId = (): string => {
      return `smultiselect_${Math.round(
        (Math.random() * Date.now()) % 103183
      )}`;
    };
  }
};

export default samMultiselectComponent;
