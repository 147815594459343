import * as moment from "moment";
import { Object } from "es6-shim";

/**
 * A directive that displays a custom datepicker
 * version: 1.0.0
 * description: See gitlab for detailed summary and examples
 *
 * Arguments:
 *     pickerStartdate   : string       A valid date/datetime to be displayd initially
 *     pickerEnddate     : string       A valid date/datetime to be displayd initially
 *     pickerDateschanged: functions    A callback for when a user picks dates
 *                                          from the datepicker.
 *     pickerDisabled    : expression   If this expression evaluates to true,
 *                                         the datepicker will not display.
 *                                      The expression is evaluated each time
 *                                          the user chooses dates from the calendars
 *                                          and when the user chooses one of the
 *                                          side-labels (Next week, Last 3 weeks, etc.)
 *      pickerMaxrange   : expression   A momentjs duration object using whole range name
 *                                          OK:     {'weeks': 4} or {'months': 5} or {'hours': 4}
 *                                          NOT OK: {'m': 4} or {'h': 4} or {'m': 5}
 */
const samDatepickerComponent: ng.IComponentOptions = {
  bindings: {
    pickerStartdate: "<",
    pickerEnddate: "<",
    pickerDateschanged: "=",
    pickerDisabled: "&",
    pickerMaxrange: "&"
  },
  template: `<div class="sam-datepicker">
                <input type="text" class="form-control sam-datepicker-input"
                ng-readonly="true">
                <i class="sam-datepicker-icon vgIcon svgCalendar"></i>
                </div>`,
  controller: class SamDatePickerController {
    static $inject: string[] = ["$element"];

    // Bindings
    private pickerStartdate: Date;
    private pickerEnddate: Date;
    private pickerDateschanged?: Function;
    private pickerDisabled: Function;
    private pickerMaxrange: Function;

    private element: ng.IRootElementService;
    private startDate?: Date | moment.Moment;
    private endDate?: Date | moment.Moment;

    constructor($element: ng.IRootElementService) {
      this.element = $element;
    }

    /**
     * Angular component lifecycle hook when the component is initialized.
     * We use this hook to initialize the daterangepicker plugin with the
     * appropriate values, and to cache the date values in the component itself.
     */
    $onInit = (): void => {
      // Initialize the picker.
      // $element is a reference to the DOM object itself.
      this.initPicker();
    };

    /**
     * Angular component lifecycle hook when the component bindings change.
     * We use this hook to update the dates in the daterangepicker itself and
     * cache new date values in the component itself.
     * Check if this is the first change of the binding,
     * before the component is initialized.
     * If it's not the first change, we have received new
     * values from the parent controller/component and set
     * them appropriately.
     * @param  {object}     changes       Object containing changes of bindings
     */
    $onChanges = (changes: any): void => {
      if (changes.pickerStartdate && !changes.pickerStartdate.isFirstChange()) {
        this.updateDateInPicker("start", changes.pickerStartdate.currentValue);
      }

      if (changes.pickerEnddate && !changes.pickerEnddate.isFirstChange()) {
        this.updateDateInPicker("end", changes.pickerEnddate.currentValue);
      }
    };

    private initPicker = (): void => {
      this.startDate = moment(this.pickerStartdate, "YYYY-MM-DD");
      this.endDate = moment(this.pickerEnddate, "YYYY-MM-DD");

      const daterangepickerDefaults: daterangepicker.Settings = {
        parentEl: ".sam-datepicker",
        dateLimit: this.pickerMaxrange(),
        startDate: this.startDate.isValid()
          ? this.startDate
          : moment(new Date(), "YYYY-MM-DD"),
        endDate: this.endDate.isValid()
          ? this.endDate
          : moment(new Date(), "YYYY-MM-DD"),
        showISOWeekNumbers: true,
        opens: "right",
        drops: "down",
        locale: this.getPickerLocale(),
        ranges: this.getPickerRanges(),
        linkedCalendars: false,
        autoApply: true,
        alwaysShowCalendars: true,
        autoUpdateInput: true
      };

      // Locate the input (the actual daterangepicker input element)
      const domElem: any = this.element.find("input");

      // Initialize the daterangepicker jquery plugin, the view-value and
      // bind to the jquery plugin's events
      // TODO: Get rid of jQuery
      domElem.daterangepicker(daterangepickerDefaults, this.datePickerCallBack);
    };

    /** Determine labels for our datepicker from moment's configured locale */
    private getPickerLocale = (): daterangepicker.Locale => {
      const locale = moment.locale();
      let newObject: daterangepicker.Locale = {
        applyLabel: "Velja",
        cancelLabel: "Hætta við",
        fromLabel: "Frá",
        toLabel: "Til",
        weekLabel: "V",
        customRangeLabel: "Sérvalið tímabil",
        daysOfWeek: moment.weekdaysShort(),
        monthNames: moment.months(),
        firstDay: moment.localeData().firstDayOfWeek()
      };

      if (locale !== "is") {
        newObject = {
          applyLabel: "Apply",
          cancelLabel: "Cancel",
          fromLabel: "From",
          toLabel: "To",
          weekLabel: "W",
          customRangeLabel: "Custom date range",
          daysOfWeek: moment.weekdaysShort(),
          monthNames: moment.months(),
          firstDay: moment.localeData().firstDayOfWeek()
        };
      }

      return Object.assign(newObject, {
        format: "DD.MM.YYYY",
        separator: " - "
      });
    };

    /** Determine labels for our custom ranges from moment's configured locale */
    private getPickerRanges = (): any => {
      const locale = moment.locale();
      if (locale === "is") {
        return {
          "Næstu 2 vikur": [moment(), moment().add(2, "weeks")],
          "Síðasta vika": [moment().subtract(1, "weeks"), moment()],
          "Síðustu 2 vikur": [moment().subtract(2, "weeks"), moment()],
          "Síðasti mánuður": [moment().subtract(1, "months"), moment()],
          "Síðustu 3 mánuðir": [moment().subtract(3, "months"), moment()]
        };
      }
      return {
        "Next 2 weeks": [moment(), moment().add(2, "weeks")],
        "Last week": [moment().subtract(1, "weeks"), moment()],
        "Last 2 weeks": [moment().subtract(2, "weeks"), moment()],
        "Last month": [moment().subtract(1, "months"), moment()],
        "Last 3 months": [moment().subtract(3, "months"), moment()]
      };
    };

    /**
     * Called whenever the daterangepicker plugin has updated the date
     * range value. If the user clicks 'Apply'/'Velja' with the date range
     * unchanged, this function is not called. Only when the value changes.
     * @param  {moment}     start       Range's startDate chosen by the user
     * @param  {moment}     end         Range's endDate chosen by the user
     * @param  {string}     label       Label from the daterangepicker plugin
     */
    private datePickerCallBack = (start: any, end: any): void => {
      if (this.pickerDateschanged) {
        this.pickerDateschanged(start, end);
      }
    };

    private updateDateInPicker = (type: any, date: any): void => {
      // Make sure to get new accessToken (call 'me' endpoint) on page refresh
      localStorage.removeItem("accessTokenTime");

      const domElem: any = this.element.find("input");
      if (type === "start") {
        this.startDate = date;
        domElem
          .data("daterangepicker")
          .setStartDate(moment(this.startDate, "YYYY-MM-DD"));
      } else if (type === "end") {
        this.endDate = date;
        domElem
          .data("daterangepicker")
          .setEndDate(moment(this.endDate, "YYYY-MM-DD"));
      }
    };
  }
};

export default samDatepickerComponent;
