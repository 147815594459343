import * as angular from "angular";
import { Object } from "es6-shim";
import { $compile, $interpolate, $timeout } from "ngimport";
import { $uibPosition } from "Bootstrap/angular";
import * as componentTemplate from "Components/samAsyncDropdown/samAsyncDropdown.html";
import * as dropdownListTemplate from "Components/samAsyncDropdown/dropdown.html";
import { EventUtilities, WindowUtilities } from "Utilities";

declare var window: any;

/**
 * samAsyncDropdown is just a regular dropdown in the sense that it displays a
 * list of items/links for the user to interact with. The main difference
 * between this dropdown and, for example the uibDropdown, is that the list's
 * contents is only determined when the dropdown is opened for the first time
 * but not when the component is rendered for the first time.
 * For more info: https://docs.google.com/document/d/1KlEGu7JWKGcAemgKisGPb1OICI9vBqvFjQKxowHgUfs
 *
 * Arguments/attributes:
 *    @param {Function} items            A function that should return a
 *          Promise. The component calls this function when the dropdown is
 *          opened. The Promise should be resolved/rejected with an array of
 *          list-items. If your list consists of items that need to be fetched
 *          from - or depends on logic from - remote sources, you can update the
 *          state of the dropdown by using promise notify method, passing
 *          a list of fetched items until the promise is resolved. This
 *          attribute is required and the component throws an error if it’s not
 *          present.
 */
const samAsyncDropdownComponent: ng.IComponentOptions = {
  template: `${componentTemplate}`,
  bindings: {
    items: "&",
    onError: "@"
  },
  transclude: true,
  controller: class SamAsyncDropdownController {
    static $inject: string[] = ["$scope", "$element"];

    // Bindings
    private items: Function;
    private onError: string;

    private currentScope: any;
    private currentDOMElement: ng.IRootElementService;
    private dropdownDOMElement: any;
    private menuItems: any[];
    private fetchingItems: boolean;
    private fetchError: boolean;

    constructor($scope: any, $element: ng.IRootElementService) {
      this.currentScope = $scope;
      this.currentDOMElement = $element;
      this.currentDOMElement[0].classList.add("dropdown");

      // TODO: Find out how to automatically position the dropdown both horizontally and vertically.
      // If we put following classes on the sam-async-dropdown element:
      // - 'dropdown': The dropdown will open downwards.
      // - 'dropup': The dropdown will open upwards.
      // If we put following classes on the dropdown element:
      // - 'dropdown-menu-left': The dropdown will open to the left.
      // - 'dropdown-menu-right': 'The dropdown will open to the right.
    }

    /**
     * Component initialization. There is no listener binding or compilation
     * going on here. This should just be a place to check for required
     * attributes an initialize internal variables.
     */
    $onInit = (): void => {
      this.menuItems = [];
    };

    /**
     * Component deconstructione. Just calls teardown to remove the dropdown,
     * clean up event-handlers, etc.
     */
    $onDestroy = (): void => {
      this.teardown();
    };

    /**
     * Called when the user interacts with the main dropdown button.
     */
    toggleDropdown = (e: any): void => {
      if (!this.dropdownDOMElement) {
        this.openDropdown(e);
      } else {
        this.teardown();
      }
    };

    /**
     * Compile element with certain CSS elements.
     * Return promise.
     * @param {object} element Element to compile
     */
    private dropdownElementCreated = (
      element: string
    ): SamskipPromise<HTMLElement> => {
      return <SamskipPromise<any>>(
        Promise.resolve($compile(element)(this.currentScope))
      );
    };

    /**
     * Compile the template for the drodown-list, and then pass
     * it on when the promise is resolved.
     */
    private openDropdown = (e: any): void => {
      e.stopPropagation();

      this.dropdownElementCreated(`${dropdownListTemplate}`).then(
        (data: HTMLElement) => {
          this.dropdownDOMElement = data;
          this.displayDropdown();
        }
      );
    };

    /**
     * Display dropdown element and then a fetch all its items to
     * be displayed and finally render the list.
     * @param {object} dropdownElement Dropdown HTML element
     */
    private displayDropdown = (): void => {
      // Append dropdown element to the current element
      this.currentDOMElement[0].appendChild(this.dropdownDOMElement[0]);
      // Display block, because default is 'hidden'
      this.dropdownDOMElement[0].style.display = "block";
      this.dropdownDOMElement[0].classList.add("dropdown-menu-right");

      this.fetchingItems = true;
      this.bindOutsideClick();

      this.items()
        .then((items: any) => {
          this.menuItems = this.createMenuItems(items);
        })
        .then(() => {
          this.fetchingItems = false;

          this.currentScope.$digest();
        });
    };

    private bindOutsideClick = (): void => {
      EventUtilities.onetime(window.document, "click", this.teardown);
    };

    /**
     * Maps the menu-items to a new list with $interpolated labels, inserts
     * dividers and checks for 'sref' properties ('ui-sref').
     * @param  {Array}  items   An array of dropdown-item objects.
     * @return {Array}          Returns an array of the dropdown-items as used
     *                          by the component.
     */
    private createMenuItems = (items: any): any[] => {
      const list = items
        .filter((item: any) => item)
        .map((item: any) => {
          if (item === "divider") {
            return {
              divider: true
            };
          }

          const result: any = {
            label: $interpolate(item.label)(undefined),
            handleClick: item.onClick || (() => undefined),
            href: item.href || "",
            target: item.target || "",
            iconClass: item.iconClass || undefined,
            badge: item.badge || undefined
          };

          // Check for the sref (ui-sref) and srefOpts (ui-sref-opts)
          if (item.sref) {
            result.sref = item.sref;
            if (item.srefOpts) {
              result.srefOpts = item.srefOpts;
            }
          }
          return result;
        });

      // Remove dividers from the start and end of the array
      if (list.length && list[0].divider) {
        list.shift();
      }
      if (list.length && list[list.length - 1].divider) {
        list.pop();
      }
      return list;
    };

    /**
     * Removes the dropdown element, cleans up event-listeners and returns the
     * component to its original state.
     */
    private teardown = (): void => {
      if (!this.dropdownDOMElement) return;
      this.dropdownDOMElement.remove();
      this.dropdownDOMElement = undefined;
      this.fetchError = false;
    };
  }
};

export default samAsyncDropdownComponent;
