import * as angular from "angular";
import { autorun, reaction } from "mobx";
import * as introJs from "intro.js";
import { $timeout, $compile } from "ngimport";

import { TutorialService, TranslationService } from "Services";

const introJs = require("intro.js").introJs;

/**
 * <sam-intro> directive
 * ATTRIBUTES:
 *   steps: Array of steps (element, title, content, position)
 *   step:  Which step to start on (default 1)
 *   group: Tutorial group it belongs to (cards, sidebar, etc.)
 */
const samIntroComponent: ng.IComponentOptions = {
  bindings: {
    steps: "<",
    step: "<",
    group: "<",
  },
  template:
    '<a href="#" ng-click="$ctrl.launchIntro();"><i class="fa fa-question"></i></a>',
  controller: class SamIntroController {
    static $inject: string[] = ["$scope", "$element"];

    // Bindings
    private steps: any[];
    private step: number;
    private group: string;

    private reactionFn: Function;

    private currentDOMElement: ng.IRootElementService;
    private currentScope: any;
    private readonly props: any = {};
    private isOpen: boolean = false;
    private introLib?: introJs.IntroJs;
    private offTranslateChangeSuccess: Function;

    private readonly events = {
      onexit: () => {
        this.introTearDown();
      },
      oncomplete: () => {
        this.introTearDown();
      },
      onafterchange: () => {
        this.introEventListener();
      },
    };

    constructor($scope: any, $element: ng.IRootElementService) {
      this.currentScope = $scope;
      this.currentDOMElement = $element;

      this.reactionFn = autorun(() => {
        if (TranslationService.getSelectedLanguage()) {
          this.loadTranslations();
        }
      });
    }

    /**
     * Initializes the directive
     * @param  {Number} stepNumber Number of step to start on (default 1)
     * @param  {String} group      Tutorial group the element belongs to
     * @param  {DOM}    element    DOM element of the directive
     */
    $onInit = (): void => {
      this.step = Number(this.step);
      this.props.stepNumber = !this.step ? 1 : this.step;
      this.props.group = this.group;
      this.props.options = {
        steps: this.steps,
        showStepNumbers: false,
        showBullets: true,
        exitOnOverlayClick: true,
        exitOnEsc: true,
        nextLabel: '<span data-translate="LABEL_NEXT">Næsta</span>',
        prevLabel: '<span data-translate="LABEL_PREVIOUS">Fyrri</span>',
        skipLabel: '<span data-translate="LABEL_CLOSE">Loka</span>',
        doneLabel: '<span data-translate="LABEL_CLOSE">Loka</span>',
      };

      this.isOpen = false;

      // checks if the tutorial has been read before and adds pulse if not
      if (!TutorialService.tutorialRead(this.props.group)) {
        this.currentDOMElement.addClass("first-view");
      }
    };

    $onDestroy = (): void => {
      this.reactionFn();
    };

    /**
     * Launches the intro
     */
    launchIntro = (): void => {
      if (this.isOpen) return;

      this.isOpen = true;

      this.introLib = this.introInit();

      if (!this.introLib) return;

      // if step is number use step, else use stepnumber
      if (this.step > 0) {
        this.introLib.goToStep(this.step).start();
      } else {
        this.introLib.start();
      }

      // Mark the tutorial group as done/viewed
      TutorialService.markTutorialDone(this.props.group);

      // Go through all <sam-intro> elements within the tutorial group and
      // remove 'first-view' class
      angular
        .element(`sam-intro[group=${this.props.group}]`)
        .removeClass("first-view");
    };

    /**
     * Initializes Intro.js library
     */
    private introInit = (): introJs.IntroJs | undefined => {
      /* globals introJs */
      this.introLib = introJs();

      if (!this.introLib) return;

      this.introLib.setOptions(this.props.options);
      this.introLib.onexit(this.events.onexit);
      this.introLib.oncomplete(this.events.oncomplete);
      this.introLib.onafterchange(this.events.onafterchange);
      document.addEventListener("IntroLoaded", this.introEventListener, false);

      return this.introLib;
    };

    /**
     * Tears down the Intro.js library
     */
    private introTearDown = (): void => {
      this.isOpen = false;
      this.introLib = undefined;

      document.removeEventListener("IntroLoaded", this.introEventListener);
    };

    /**
     * Inserts icons into the intro content
     * @param  {String} text Text to replace
     * @return {String}      Replaced text with icons
     */
    private insertIcons = (text: string): string => {
      let result;
      // Icon for home rotate
      result = text.replace(
        "[icon]",
        '<span class="rotate icon-icon_flip" style="right: 0px;"></span>'
      );
      // Icon for listpage select
      result = result.replace(
        "[icon(select)] ",
        [
          '<input type="checkbox" class="css-checkbox" id="checkbox1">',
          '<label class="css-label lite-gray-check"></label>',
        ].join("")
      );

      // Icon for listpage action
      result = result.replace(
        "[icon(hnappar)] ",
        `
                <span class="dropdown u-inlineBlock">
                <button style="
                position: relative; top: -3px;padding: 5px;
                background: #ccd3da;border-radius: 3px;border: none;
                -webkit-transition: background 0.6s;transition: background 0.6s;"
                class="btn btn-default dropdown-toggle">
                <span style="display: block;margin: 1px 0;height: 2px;
                width: 12px;background: #ffffff;"
                class="icon-bar"></span>
                <span style="display: block;margin: 1px 0;height: 2px;
                width: 12px;background: #ffffff;"
                class="icon-bar"></span>
                <span style="display: block;margin: 1px 0;height: 2px;
                width: 12px;background: #ffffff;"
                class="icon-bar"></span></button></span>
                `
      );

      return result;
    };

    /**
     * Loads translations and inserts HTML into 'intro' property
     */
    private loadTranslations = () => {
      // Go through each item in the steps array
      // and create a HTML element to hand to Intro.js
      this.steps.forEach((item: any) => {
        const heading = TranslationService.translate(item.title);
        const content = TranslationService.translate(item.content);

        item.intro =
          '<div id="tutorial" class="tutorial-tip">' +
          `<h3>${heading}</h3>` +
          `<p>${this.insertIcons(content)}</p></div>`;
      });
    };

    /**
     * Compiles the 'introjs-tooltip' class to $scope
     */
    private introEventListener = (): void => {
      $timeout(() => {
        $compile(".introjs-tooltip")(this.currentScope);
      });
    };
  },
};

export default samIntroComponent;
